import { Box } from "@material-ui/core";
import { Component } from "react";

class HeaderBizofit extends Component {
    render() {
        return (
            <>
            <Box alignContent='center' style={{textAlign: 'center'}}>
                <h2>Bizofit</h2>
            </Box>
            </>
        );
    }
}

export default HeaderBizofit;