import React, { Component } from "react";
import Pagination from 'react-bootstrap/Pagination';
import http from "../../../../http-common";
import { Box, Avatar, Grid, Button, Modal, IconButton } from "@material-ui/core";
import Rating from "../../../view/formComponent/rating";
import ShareIcon from "@material-ui/icons/Share";
import { withRouter } from 'react-router-dom';
import SearchBar from '../SearchBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import rootConfig from "../../../../config";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import CookiesService from '../../../../services/CookiesService';

var FormData = require('form-data');

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

class SpecializationCompanyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyList: [],
            search: "",
            isActive: true,
            totalCompany: "0",
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
            paginationIteams: [],
            actualTotalPage: 0,
            requestParms: props.location.state,
            openModel: false,
            is_recommendedapi: true,
            add_to_inbox: false
        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.callAPIOnPagination = this.callAPIOnPagination.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.recommendedApi = this.recommendedApi.bind(this);
        this.handleModelClose = this.handleModelClose.bind(this);
        this.handleModelOpen = this.handleModelOpen.bind(this);
        this.addToInbox = this.addToInbox.bind(this);
        this.handleShare = this.handleShare.bind(this);
    }

    handleShare(id) {
        const company = this.state.companyList.filter((item) => item.ID == id);
        console.log('company', company);
        var parmas = {
            company: company,
            isCompanyShare: true,
        }
        this.props.history.push({pathname: '/submit-idea', state: { parmas }});
    }

    handleChangeSearch(newValue) {
        this.state.search = newValue;
        this.setState({ isActive: true });
        // console.log("search", this.state.search);
        this.callAPIOnPagination();
    }

    handlePageClick(event) {
        console.log(event);
        //console.log(Number(event.target.id));
        this.state.currentPage = parseInt(event.target.id);
        //this.setState({currentPage:parseInt(event.target.id)});
        this.state.paginationIteams = [];
        this.setState({ isActive: true });
        this.callAPIOnPagination();
    }

    callAPIOnPagination() {
        var data = new FormData();
        data.append('term_id', this.state.requestParms.term_id);
        data.append('page', this.state.currentPage);
        data.append('per_page', '10');
        data.append('search', this.state.search);
        // console.log("data", data.term_id);
        // data.append('search', this.state.search);
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL || rootConfig.BaseURL + 'api/getInnovatorsList',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };
        var self = this;
        axios(config)
            .then(function (response) {
                console.log("after api calling", response.data.data);
                // self.setState({ companyList: response.data.data });
                self.state.companyList = response.data.data;
                console.log("companyList", self.state.companyList[0].ID);
                self.state.totalCompany = parseInt(response.data.total_count);
                var total_pages = Math.ceil(self.state.totalCompany / self.state.perPage);

                self.state.actualTotalPage = total_pages;

                self.state.totalPages = (total_pages > 10) ? 10 : total_pages;

                var newPaginationItems = [];
                newPaginationItems.push(<Pagination.First onClick={self.firstPage} />,
                    <Pagination.Prev onClick={self.previousPage} />);
                for (let number = 1; number <= self.state.totalPages; number++) {
                    newPaginationItems.push(
                        <Pagination.Item key={number} active={number === self.state.currentPage} id={number} onClick={self.handlePageClick}>
                            {number}
                        </Pagination.Item>,
                    );
                }
                newPaginationItems.push(<Pagination.Next onClick={self.nextPage} />,
                    <Pagination.Last onClick={self.lastPage} />);

                self.setState({ paginationIteams: newPaginationItems, isActive: false });
            })
            .catch(function (error) {
                console.warn("this error", error);
            });
    }

    componentDidMount() {
        this.callAPIOnPagination();
    }

    firstPage() {
        if (this.state.currentPage = 1) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    lastPage() {
        if (this.state.currentPage = this.state.actualTotalPage) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    nextPage() {
        if (this.state.currentPage < this.state.actualTotalPage) {
            this.state.currentPage = parseInt(this.state.currentPage) + 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    previousPage() {
        if (this.state.currentPage > 1) {
            this.state.currentPage = parseInt(this.state.currentPage) - 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    handleModelOpen() {
        this.setState({ openModel: true });
    }

    handleModelClose() {
        this.setState({ openModel: false })
    }

    recommendedApi() {
        this.setState({ add_to_inbox: true });
        let data = CookiesService.get("userData");
        // let data = JSON.parse(sessionStorage.getItem("userData"));
        var params = {
            user_id: data.user_id,
            company_id: data.company_id,
            term_id: this.state.requestParms.term_id
        };
        console.log(params);
        this.handleModelClose();
        http.post("api/sendIdeaRecommendations", params)
            .then((response) => {
                // console.log(response);

                this.setState({ add_to_inbox: false });
            });
    }

    addToInbox(post_id) {
        this.setState({ add_to_inbox: true });
        let data = CookiesService.get("userData");
        // let data = JSON.parse(sessionStorage.getItem("userData"));
        var params = {
            user_id: data.user_id,
            company_id: data.company_id,
            post_id: post_id
        };
        http.post("api/sendInboxIdea", params)
            .then((response) => {
                this.setState({ add_to_inbox: false });
                this.handleModelClose();
            });
    }



    render() {
        const classes = (theme) => ({
            root: {
                display: 'flex',
                '& > *': {
                    margin: theme.spacing(1),
                },
            },
            small: {
                width: theme.spacing(3),
                height: theme.spacing(3),
            },
            large: {
                width: theme.spacing(10),
                height: theme.spacing(10)
            },
        });
        return (
            <>
                <div className='content-wrapper'>
                    <Grid container>
                        <Grid md={12}>
                            <LoadingOverlay
                                active={this.state.add_to_inbox}
                                spinner
                                text='Please wait...'
                            >
                                <SearchBar placeholder="Search Category by try typing 'Company'" onChangeValue={this.handleChangeSearch} />
                                <Box pl={2}><h1>{this.state.requestParms.term_name}</h1></Box>
                                {this.state.isActive ? <div style={{ position: "absolute", top: "150px", left: "50%" }}><CircularProgress /></div> :
                                    this.state.companyList.map((item, index) => (
                                        <Box boxShadow={2} bgcolor='background.paper' m={1} p={1} >
                                            <Grid md={12} className='d-flex'>
                                                <Grid md={1} style={{margin: 'auto'}}>
                                                    <Avatar
                                                        alt='Remy Sharp'
                                                        src={item?.attachment}
                                                        // className={classes.large}
                                                        style={{ width: "95px", height: "95px" }}
                                                        onClick={() => { this.props.history.push({ pathname: '/company-detail', state: { post_id: item.ID } }) }}
                                                    />
                                                </Grid>
                                                <Grid md={10}>
                                                    <Box pl={2}>
                                                    <h4 onClick={() => { this.props.history.push({ pathname: '/company-detail', state: { post_id: item.ID } }) }}>{item?.post_title}</h4>
                                                    <Rating
                                                        rating={item?.avg_rating}
                                                        reviews_count={item?.reviews_count}
                                                    />

                                                    <p className='fs-14'>{item?.post_content.substr(0, 250) + "..."}</p>
                                                    <h4>
                                                        BizofitScore:{" "}
                                                        <span className='color-orange fs-14'>
                                                            {item?.bizofit_score}
                                                        </span>
                                                    </h4>
                                                    </Box>
                                                </Grid>
                                                <Grid md={2}>
                                                    <div className='icon-wrap'>
                                                        <IconButton onClick={this.handleShare.bind('id',item.ID)}><ShareIcon style={{ color: "#ff720a", fontSize: 25 }}/></IconButton>
                                                    </div>
                                                    {item?.is_recommended == 1 ?
                                                        <Button variant="contained" style={{ backgroundColor: "#ff720a", color: "#ffffff", textTransform: 'none', marginTop: "110px" }} size="large" onClick={this.handleModelOpen}>Recommended</Button> :
                                                        <Button variant="contained" style={{ backgroundColor: "#ff720a", color: "#ffffff", textTransform: 'none', marginTop: "110px" }} size="large" onClick={this.addToInbox.bind('post_id', item.ID)}>+ Add to Inbox</Button>}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    ))}
                            </LoadingOverlay>
                        </Grid>
                        <Box justifyContent="center" style={{ margin: "auto" }}>
                            <Pagination>{this.state.paginationIteams}</Pagination>
                        </Box>
                        <Modal
                            open={this.state.openModel}
                            onClose={this.handleModelClose}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                        >
                            <div style={{ position: "absolute", top: "10%", left: "40%", backgroundColor: "white", padding: "20px" }}>
                                <h2 id="simple-modal-title">Get recommendedation in your inbox</h2>
                                <div style={{ margin: "auto", margin: "20px" }}>
                                    <Button variant="outlined" style={{ textTransform: 'none', fontSize: "20px", marginLeft: "28%" }} size="large" onClick={this.recommendedApi}>Ok</Button>
                                    <Button variant="contained" style={{ backgroundColor: "#ff720a", color: "#ffffff", textTransform: 'none', fontSize: "20px", marginLeft: "10%" }} size="large" onClick={this.handleModelClose}>Skip</Button>
                                </div>
                            </div>
                        </Modal>


                    </Grid>
                </div>
            </>
        );
    };

}

export default withRouter(SpecializationCompanyList);