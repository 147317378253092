import { Component } from "react";
import { Avatar, Box, Grid, Typography, Button, InputLabel, IconButton } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import TodayIcon from '@material-ui/icons/Today';
import CloseIcon from '@material-ui/icons/Close';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import CookiesService from '../../../../services/CookiesService';
import http from "../../../../http-common";

class CreateProblem extends Component {
    constructor(props) {
        super(props);
        const requestParams = this.props.location.state?.params ? this.props.location.state?.params : [];
        this.state = {
            requestParams: [],
            category_id: requestParams.category_id? requestParams.category_id:0,
            category_name: requestParams.category_name? requestParams.category_name:'', 
            invalidImage: requestParams.invalidImage? requestParams.invalidImage:'',
            file_name: requestParams.file_name? requestParams.file_name:'',
            file_data: requestParams.file_data? requestParams.file_data:'',
            description: requestParams.description? requestParams.description:'',
            Users: requestParams.Users? requestParams.Users:[],
            user_id: requestParams.user_id? requestParams.user_id:0,
            idea_title: requestParams.idea_title? requestParams.idea_title:'',
            status: 'published',
            challenge_type: '',
            problem_id: requestParams.problem_id? requestParams.problem_id:0,
            company_id: requestParams.company_id? requestParams.company_id:0,
            company_name: requestParams.company_name? requestParams.company_name:'',
            company_description: requestParams.company_description? requestParams.company_description:'',
            company_avg_rating: requestParams.company_avg_rating? requestParams.company_avg_rating:0,
            company_business_website: requestParams.company_business_website? requestParams.company_business_website:'',
            is_company_shared: requestParams.is_company_shared? requestParams.is_company_shared:0,
            company_post_id: requestParams.company_post_id? requestParams.company_post_id:0,
            isActive: false,
            success: '',
            is_select_date: false,
            DeadLine: requestParams.DeadLine? requestParams.DeadLine:''// moment(new Date()).format("DD-MMM-YYYY")
        }
        this.handelCalender = this.handelCalender.bind(this);
        this.handelDeadline = this.handelDeadline.bind(this);
        this.selectCategory = this.selectCategory.bind(this);
        this.removeCategory = this.removeCategory.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
        this.fileValidate = this.fileValidate.bind(this);
        this.sendProblem = this.sendProblem.bind(this);
        this.setChallangeType = this.setChallangeType.bind(this);
    }

    sendProblem() {
        this.setState({isActive: true});
        var userData = CookiesService.get("userData");
        if(this.state.DeadLine == '') {
            this.setState({ error: 'please select deadline date', isActive: false}, () => {
                window.setTimeout(() => {
                    this.setState({ error: '' });
                }, 3000)
            });
        } else {
            var params = {
                user_id: userData.user_id,
                company_id: userData.company_id,
                description: this.state.description,
                category: this.state.category_name,
                status: this.state.status,
                challenge_type: this.state.challenge_type,
                deadline_date: moment(this.state.DeadLine).format("YYYY-MM-DD"),
                term_id: this.state.category_id,
                file_name: this.state.file_name,
                file_data: this.state.file_data,
            }
            console.log('api params', params);
            http.post('/Api/createProblemNew', params)
                .then((response) => {
                    if(response.data.status == "success") {
                        this.setState({ success: response.data.message, isActive: false}, () => {
                            window.setTimeout(() => {
                                this.setState({ success: '' });
                            }, 3000)
                        });
                    } else {
                        this.setState({ error: response.data.message, isActive: false}, () => {
                            window.setTimeout(() => {
                                this.setState({ error: '' });
                            }, 3000)
                        });
                    }
                });
        }
    }

    setChallangeType(event) {
        this.state.challenge_type = event.target.value;
        console.log('challenge_type', this.state.challenge_type)
    }

    handleDescription(event) {
        this.state.description = event.target.value;
    }

    fileValidate(event) {
        const imageFile = event.target.files[0];

        if (!imageFile) {
            this.setState({ invalidImage: 'Please select image.' });
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            this.setState({ invalidImage: 'Please select valid image.' });
            return false;
        }
        const reader = new FileReader();

        reader.readAsDataURL(imageFile);
        reader.onload = (e) => {
            console.log("jhasjd");
            this.state.file_name = imageFile.name;
            this.state.file_data = reader.result;
            this.setState({ invalidImage: null });
        };

    }

    removeCategory() {
        this.setState({category_name: '', category_id: 0});
    }
    selectCategory() {
        var params =  this.state;
        this.props.history.push({ pathname: 'select-category', state: {params}});
        
    }
    handelCalender() {
        this.setState({is_select_date : !this.state.is_select_date});
    }
    handelDeadline(value, event) {
        var date = moment(value).format("DD-MMM-YYYY");
        this.setState({DeadLine : date});
    }
    render() {
        const classes = {
            widthFull: {
                position: "reletive",
                fontSize: 15,
                width: '100%',
                top: 10,
                right: 5
            },
            large: {
                width: "90px",
                height: "90px",
            },
            sizeIcon: {
                fontSize: 35,
                color: "#fe7500"
            },
            textCenter: {
                textAlign: "center",
            },
            buttonStyle: {
                backgroundColor: "#fe7500",
                color: "#fff",
                fontSize: 15,
                marginLeft: 10,
                textTransform: "none",
                float: "right"
            },
            textOrenge: {
                color: "#fe7500",
                marginTop: "8px",
                position: 'absolute'
            },
            arrow: {
                cursor: "pointer"
            },
            errorMessage: {
                color: 'red',
            },
            category: {
                backgroundColor: "#bdbdbd",
                padding: "2px 10px",
                borderRadius: "50px",
                color: "#fff",
                fontSize: "15px"
            },
            calenderCss: {
                position: 'absolute',
                marginTop: '-28px',
                marginLeft: '30px'
            },
            closebutton: {
                color: "#fff",
                fontSize: 15,
            },
            fontStyle: {
                fontSize: '20xpx'
            }
        }
        var userData = CookiesService.get("userData");
        return (
            <div class="content-wrapper">
                <Grid container>
                {this.state.success && <div class="alert alert-success" role="alert" style={classes.widthFull}>{this.state.success}</div>}
                        {this.state.error && <div class="alert alert-danger" role="alert" style={classes.widthFull}>{this.state.error}</div>}
                    <Grid md={12}>
                        <Grid md={8}>
                            <Box m={2} borderColor="grey.500" borderBottom={1}>
                                <Grid md={12} className='d-flex'>
                                    <Grid md={2}>
                                        <Avatar style={classes.large} src={userData.picture_url}></Avatar>
                                    </Grid>
                                    <Grid md={12}>
                                        <Box ml={2}>
                                            <Typography variant="h2" gutterBottom>{userData.company_name}</Typography>
                                            <Typography variant="h5" gutterBottom>{userData.designation}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid md={12}>
                                        <Box justifyContent='center' style={{textAlign: 'center'}} className='d-flex'>
                                            <Grid md={12}>
                                            <Button variant="contained" size="small" style={classes.buttonStyle} onClick={this.selectCategory}>+ Add Category</Button>
                                            </Grid>
                                            <Grid md={12}>
                                            <TodayIcon style={classes.sizeIcon} onClick={this.handelCalender}/>
                                            <p>{this.state.DeadLine ? this.state.DeadLine:'Select DeadLine'}</p>
                                            {this.state.is_select_date && <Box boxShadow={8} style={classes.calenderCss}> <Calendar onChange={this.handelDeadline} minDate={new Date()} /><Button variant="outlined" color="primary" onClick={this.handelCalender}>ok</Button></Box>}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid>
                                <Box justifyContent="center" m={2} alignItems="center" style={classes.textCenter} borderBottom={1}>
                                <Grid md={12}>
                                    <Grid>{this.state.category_name && <span style={classes.category}>{this.state.category_name} <IconButton style={classes.closebutton} onClick={this.removeCategory}><CloseIcon /></IconButton></span>}</Grid>
                                    <textarea
                                        rows={20}
                                        style={{width: '100%'}}
                                        placeholder={"Problem statement"}
                                        name='description'
                                        onChange={this.handleDescription}
                                    >{this.state.description}</textarea>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Grid md={12} className='d-flex'>
                                        <Grid md={5}>
                                            <label for="attachment" style={classes.arrow}>
                                                <AttachFileIcon style={classes.sizeIcon}></AttachFileIcon><span style={classes.textOrenge}>attachment</span>
                                                {this.state.invalidImage && <p style={classes.errorMessage}>{this.state.invalidImage}</p>}
                                                <input type="file" name="attachment" id="attachment" style={{ display: "none" }} accept="image/png,image/jpg,image/jpeg" onChange={this.fileValidate} />
                                            </label>
                                            <div> {this.state.file_data && <img src={this.state.file_data} height='100' width='100' />}</div>
                                        </Grid>
                                        <Grid md={7} justify="flex-end">
                                            <Button variant="contained" size="large" style={classes.buttonStyle} data-toggle="modal" data-target="#exampleModal">Send</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <!-- Modal --> */}
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Select Challange Type</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ui style={classes.fontStyle}>
            <li><input type='radio' name='challenge_type' value='Internal Challange' id='internal_challange' onClick={this.setChallangeType} /> <label for='internal_challange'>Internal Challange</label></li>
            <li><input type='radio' name='challenge_type' value='Open Challange' id='open_challange' onClick={this.setChallangeType} /> <label for='open_challange'>Open Challange</label></li>
            <li><input type='radio' name='challenge_type' value='Project' id='project' onClick={this.setChallangeType} /> <label for='project'>Project</label></li>
            <li><input type='radio' name='challenge_type' value='Expert Consultaion Required' id='expert' onClick={this.setChallangeType} /> <label for='expert'>Expert Consultaion Required</label></li>
        </ui>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" onClick={this.sendProblem} data-dismiss="modal">Send Problem</button>
      </div>
    </div>
  </div>
</div>
            </div>
        )
    }
}

export default CreateProblem;