import { Avatar, Box, Grid } from "@material-ui/core";
import React, { Component } from "react";
import SearchBar from '../SearchBar';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import http from "../../../../http-common";
import Pagination from 'react-bootstrap/Pagination';
import NoDataFound from "../../../NoDataFound";

class Enterprises extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            enterprisesList: this.props.Solutionists,
            totalCompany: 0,
            isActive: true,
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
            paginationIteams: [],
            actualTotalPage: 0,
            noDataFound: false
        }
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.callAPIOnPagination = this.callAPIOnPagination.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
    }

    handleChangeSearch(newValue) {
        this.state.search = newValue;
        // console.log("search", this.state.search);
        this.state.paginationIteams = [];
        this.setState({ isActive: true });
        this.callAPIOnPagination();
    }

    callAPIOnPagination() {
        var requestParams = {
            company_type: "Enterprises",
            page: this.state.currentPage,
            per_page: this.state.perPage,
            search: this.state.search
        };
        http.post(`/api/companiesByCompanyTypeNew`, requestParams)
            .then(response => {
                if (!response.data.total_count == 0) {
                    this.state.enterprisesList = response.data.data;
                    this.state.totalCompany = parseInt(response.data.total_count);
                    var total_pages = Math.ceil(this.state.totalCompany / this.state.perPage);
                    if (this.state.totalCompany > this.state.perPage) {
                        this.state.actualTotalPage = total_pages;

                        this.state.totalPages = (total_pages > 10) ? 10 : total_pages;

                        var newPaginationItems = [];
                        newPaginationItems.push(<Pagination.First onClick={this.firstPage} />,
                            <Pagination.Prev onClick={this.previousPage} />);
                        for (let number = 1; number <= this.state.totalPages; number++) {
                            newPaginationItems.push(
                                <Pagination.Item key={number} active={number === this.state.currentPage} id={number} onClick={this.handlePageClick}>
                                    {number}
                                </Pagination.Item>,
                            );
                        }
                        newPaginationItems.push(<Pagination.Next onClick={this.nextPage} />,
                            <Pagination.Last onClick={this.lastPage} />);

                        this.state.paginationIteams = newPaginationItems;
                    }
                    this.state.noDataFound = false;
                } else {
                    this.state.enterprisesList = [];
                    this.state.paginationIteams = [];
                    this.state.noDataFound = true;
                }
                this.setState({ isActive: false });
            });
    }

    handlePageClick(event) {
        console.log(event);
        this.state.currentPage = parseInt(event.target.id);
        this.state.paginationIteams = [];
        this.setState({ isActive: true });
        this.callAPIOnPagination();
    }

    firstPage() {
        if (this.state.currentPage = 1) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    lastPage() {
        if (this.state.currentPage = this.state.actualTotalPage) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    nextPage() {
        if (this.state.currentPage < this.state.actualTotalPage) {
            this.state.currentPage = parseInt(this.state.currentPage) + 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    previousPage() {
        if (this.state.currentPage > 1) {
            this.state.currentPage = parseInt(this.state.currentPage) - 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    componentDidMount() {
        this.callAPIOnPagination();
    }

    render() {
        const classes = {
            largeIcon: {
                fontSize: 40,
                color: "#bdbdbd"
            },
            centerAline: {
                margin: "auto"
            },
            Avatarsize: {
                width: "80px",
                height: "80px",
                fontSize: 40
            }
        }
        return (
            <>
                <Grid container>
                    <Grid md={12}>
                        <SearchBar placeholder="Search Company to send Idea to" onChangeValue={this.handleChangeSearch} />
                        <Grid md={12}>
                            {this.state.isActive ? <div style={{ position: "absolute", top: "300px", left: "50%" }}><CircularProgress /></div> :
                                this.state.enterprisesList?.map((item, index) => (
                                    <Box m={1} p={1} boxShadow={1} onClick={() => { this.props.history.push({ pathname: '/employee-list', state: { company_id: item.company_id, company_name: item.company_name, shareItem:this.props.shareItem } }) }}>
                                        <Grid md={12} className='d-flex'>
                                            <Grid md={1}>
                                                <Avatar
                                                    alt='Remy Sharp'
                                                    style={classes.Avatarsize}
                                                >{item.company_name.charAt(0)}</Avatar>
                                            </Grid>
                                            <Grid md={10}>
                                                <h3>{item.company_name}</h3>
                                            </Grid>
                                            <Grid md={1} style={classes.centerAline}>
                                                <ArrowForwardIosIcon style={classes.largeIcon} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            {this.state.paginationIteams &&
                                <Grid className="d-flex">
                                    <Box justifyContent="center" style={{ margin: "auto" }}>
                                        <Pagination>{this.state.paginationIteams}</Pagination>
                                    </Box>
                                </Grid>
                            }
                            {this.state.noDataFound == true && <NoDataFound message='No Record Found' icon='fa fa-search' />}
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withRouter(Enterprises);