import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ServiceSpecialization from "./servicespecialization";
import CompanyList from "./companyList";
import ProductList from "./productList";
import LoadingOverlay from "react-loading-overlay";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = React.useState(0);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='content-wrapper'>
      <LoadingOverlay
        active={isActive}
        spinner
        text='Please wait...'
      >
        <div className='recommend-main'>
          <div className={classes.root}>
            <AppBar position='static'>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='simple tabs example'
                className='custom-tab-main'>
                <Tab label='Service Specialization' {...a11yProps(0)} />
                <Tab label='Companies' {...a11yProps(1)} />
                <Tab label='Products' {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} >
              <ServiceSpecialization />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CompanyList />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ProductList />
            </TabPanel>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
}
