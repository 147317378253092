import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
      fontSize: "14px"
    }
  }
}));

export default function CustomizedSnackbars(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      {/* <Button variant='outlined' onClick={handleClick}>
        Open success snackbar
      </Button>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success'>
          This is a success message!
        </Alert>
      </Snackbar> */}
      {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}> */}
      {props?.isSuccess && (
        <Alert severity='success'>{props?.successMessage}</Alert>
      )}
      {props?.isInfo && <Alert severity='info'>{props?.infoMessage}</Alert>}
      {props?.isWarning && (
        <Alert severity='warning'>{props?.warningMessage}</Alert>
      )}
      {props?.isError && <Alert severity='error'>{props?.errorMessage}</Alert>}
      {/* </Snackbar> */}
    </div>
  );
}
