import React, { Component } from "react";

class Footer extends Component
{
    render()
    {
        return(
           
                 <footer class="main-footer">
                    <strong>Copyright &copy; 2018 <a href="#">Bizofit</a>.</strong> All rights reserved.
                </footer>
            
        )
    }
}

export default Footer;