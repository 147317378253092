import React, {Component} from 'react';
import { Avatar, Box, Grid, Typography, Button } from "@material-ui/core";
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';

class ApplicationLink extends Component {
    render() {
        const classes = {
            contentCenter: {
                textAlign: 'center'
            }
        }
        return (
            <div>
                <Box justifyContent="center" m={2} alignItems="center" style={classes.contentCenter} borderBottom={1}>
                    <h3>Download Innovation HQ App using links given below:</h3>
                    <Box ml={8}>
                        <Grid className='d-flex' md={12}>
                            <Grid>
                                <AndroidIcon style={{ fontSize: 50 }} />
                            </Grid>
                            <Grid>
                                <a href='https://play.google.com/store/apps/details?id=com.bizofIT&hl=en_IN'><h4>https://play.google.com/store/apps/details?id=com.bizofIT&hl=en_IN</h4></a>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box ml={8}>
                        <Grid className='d-flex' md={12}>
                            <Grid>
                                <AppleIcon style={{ fontSize: 50 }} />
                            </Grid>
                            <Grid>
                                <a href='https://apps.apple.com/us/app/innovation-hq/id1277110163'><h4>https://apps.apple.com/us/app/innovation-hq/id1277110163</h4></a>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>

        );
    }
}

export default ApplicationLink;