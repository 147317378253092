import React, { Component } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Pagination from 'react-bootstrap/Pagination';
import http from "../../../../http-common";
import { withRouter } from 'react-router-dom';
import SearchBar from '../SearchBar';
import { Box, Grid } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import NoDataFound from "../../../NoDataFound";


class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            specializationList: [],
            search: "",
            isActive: true,
            totleSpecialization: "0",
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
            paginationIteams: [],
            actualTotalPage: 0
        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.callAPIOnPagination = this.callAPIOnPagination.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
    }

    handleChangeSearch(newValue) {
        this.state.search = newValue;
        this.state.paginationIteams = [];
        this.setState({ isActive: true });
        this.callAPIOnPagination();
    }

    handlePageClick(event) {
        console.log(event);
        this.state.currentPage = parseInt(event.target.id);
        this.state.paginationIteams = [];
        this.setState({ isActive: true });
        this.callAPIOnPagination();
    }

    callAPIOnPagination() {
        var params = {
            page: this.state.currentPage,
            per_page: this.state.perPage,
            search: this.state.search
        }
        http.post("api/searchTermsDataList", params)
            .then((response) => {
                if (!response.data.total_count == 0) {
                    this.state.specializationList = response.data.data;
                    this.state.totleSpecialization = parseInt(response.data.total_count);
                    var total_pages = Math.ceil(this.state.totleSpecialization / this.state.perPage);
                    if (this.state.totleSpecialization > this.state.perPage) {
                        this.state.actualTotalPage = total_pages;

                        this.state.totalPages = (total_pages > 10) ? 10 : total_pages;

                        var newPaginationItems = [];
                        newPaginationItems.push(<Pagination.First onClick={this.firstPage} />,
                            <Pagination.Prev onClick={this.previousPage} />);
                        for (let number = 1; number <= this.state.totalPages; number++) {
                            newPaginationItems.push(
                                <Pagination.Item key={number} active={number === this.state.currentPage} id={number} onClick={this.handlePageClick}>
                                    {number}
                                </Pagination.Item>,
                            );
                        }
                        newPaginationItems.push(<Pagination.Next onClick={this.nextPage} />,
                            <Pagination.Last onClick={this.lastPage} />);
                        this.state.paginationIteams = newPaginationItems;
                    }
                    this.state.noDataFound = false;
                } else {
                    this.state.paginationIteams = [];
                    this.state.specializationList = [];
                    this.state.noDataFound = true;
                }
                this.setState({ isActive: false });
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.callAPIOnPagination();
    }

    firstPage() {
        if (this.state.currentPage = 1) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    lastPage() {
        if (this.state.currentPage = this.state.actualTotalPage) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    nextPage() {
        if (this.state.currentPage < this.state.actualTotalPage) {
            this.state.currentPage = parseInt(this.state.currentPage) + 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    previousPage() {
        if (this.state.currentPage > 1) {
            this.state.currentPage = parseInt(this.state.currentPage) - 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    render() {
        const classes = (theme) => ({
            root: {
                width: "100%",
                // maxWidth: "36ch",
                backgroundColor: theme.palette.background.paper
            },
            inline: {
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "space-between"
            }
        });

        return (
            <>
                <Grid container>
                    <Grid md={12}>
                        <SearchBar placeholder="Try typing Industrial IOT or Fintech" onChangeValue={this.handleChangeSearch} />
                        <List className={classes.root}>
                            {this.state.isActive ? <div style={{ position: "absolute", top: "50%", left: "50%" }}><CircularProgress /></div> :
                                this.state.specializationList.map((item, index) => (
                                    <>
                                        <ListItem alignItems='flex-start' className='service-list' onClick={() => { this.props.history.push({ pathname: '/specialization-company-list', state: { term_id: item.term_id, term_name: item.name } }) }}>
                                            <ListItemText
                                                //   primary='Brunch this weekend?'
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            component='span'
                                                            variant='body2'
                                                            className={classes.inline}
                                                            color='textPrimary'>
                                                            <span>{item?.name}</span>{" "}
                                                            <span className='color-red'>
                                                                {`(${item?.innovator_count})`}
                                                            </span>
                                                        </Typography>
                                                        <ArrowForwardIosIcon />
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider component='li' />

                                    </>
                                ))
                            }
                        </List>
                        {this.state.paginationIteams &&
                            <Grid className="d-flex">
                                <Box justifyContent="center" style={{ margin: "auto" }}>
                                    <Pagination>{this.state.paginationIteams}</Pagination>
                                </Box>
                            </Grid>
                        }
                        {this.state.noDataFound && <NoDataFound message='No record found' icon='fa fa-search' />}
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withRouter(ProductList);