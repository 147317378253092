import React, { useState, FormControl, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dropdown from "react-bootstrap/Dropdown";
import { render } from "react-dom";
import CustomDropDown from "./view/formComponent/customDropDown";
import http from "../http-common";
import Button from "@material-ui/core/Button";
import { Box, Grid } from "@material-ui/core";
import CookiesService from '../services/CookiesService';
import { Alert } from '@material-ui/lab';

var userData = CookiesService.get("userData");
// var userData = JSON.parse(sessionStorage.getItem("userData"));
console.log("userData", userData?.user_id);

const useStyles = makeStyles(theme => ({
  button1: {
    display: "block",
    marginTop: theme.spacing(4)
  },
  button: {
    maxWidth: '100px', maxHeight: '40px', minWidth: '100px', minHeight: '40px'
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 500
  },
  input: {
    fontSize: 30
  },
  select: {
    fontSize: 20,
    width: "1000px",
  },
  selectOption: {
    fontSize: 20
  },
  button: {
    left: "35%",
    fontSize: '20px',
    textTransform: 'none',
  },
  widthFull: {
    position: "reletive",
    fontSize: 15,
    width: '100%',
    top: 10,
    right: 5
  },
}));


function Preferences() {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=''
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}>
      {children}
      &#x25bc;
    </a>
  ));

  const classes = useStyles();

  const [timeZoneList, setTimeZoneList] = useState([
    { id: "-11", value: "(GMT-11:00) Samoa Standard Time (Pacific/Pago_Pago)" },
    { id: "-10", value: "(GMT-10:00) Pacific/Honolulu" },
    { id: "-9.30", value: "(GMT-09:30) French Polynesia (Pacific/Marquesas)" },
    { id: "-9", value: "(GMT-09:00) Alaska Standard Time (America/Anchorage)" },
    {
      id: "-8",
      value: "(GMT-08:00) Pacific Standard Time (America/Los_Angeles)"
    },
    { id: "-7", value: "(GMT-07:00) Mountain Standard Time (America/Phoenix)" },
    { id: "-6", value: "(GMT-06:00) Central Standard Time (America/Chicago)" },
    { id: "-5", value: "(GMT-05:00) Colombia Time (America/Bogota)" },
    { id: "-4", value: "(GMT-04:00) Atlantic Standard Time (America/Halifax)" },
    {
      id: "-3.30",
      value: "'(GMT-03:30) Canada Newfoundland Time (America/St_Johns)"
    },
    { id: "-3", value: "(GMT-03:00) Chile Summer Time (America/Santiago)" },
    {
      id: "-2",
      value: "(GMT-02:00) South Georgia Time (Atlantic/South_Georgia)"
    },
    { id: "-1", value: "(GMT-01:00) Cape Verde Time (Atlantic/Cape_Verde)" },
    { id: "0", value: "(GMT+00:00) Greenwich Mean Time (GMT)" },
    { id: "1", value: "(GMT+01:00) Central European Time (Europe/Paris)" },
    { id: "2", value: "(GMT+02:00) Eastern European Time (Europe/Bucharest)" },
    { id: "3", value: "(GMT+03:00) Arabian Standard Time (Asia/Riyadh)" },
    { id: "3.30", value: "(GMT+03:30) Iran Time(Asia/Tehran)" },
    { id: "4", value: "(GMT+04:00) Gulf Standard Time (Asia/Dubai)" },
    { id: "4.30", value: "(GMT+04:30) Afghanistan Time (Asia/Kabul)" },
    { id: "5", value: "(GMT+05:00) Pakistan Time (Asia/Karachi)" },
    { id: "5.30", value: "(GMT+05:30) India Standard Time (Asia/Kolkata)" },
    { id: "5.45", value: "(GMT+05:45) Nepal Time (Asia/Kathmandu)" },
    { id: "6", value: "(GMT+06:00) Bangladesh Time (Asia/Dhaka)" },
    { id: "6.30", value: "(GMT+06:30) Myanmar Time(Asia/Yangon))" },
    { id: "7", value: "(GMT+06:30) Myanmar Time(Asia/Yangon))" },
    { id: "8", value: "(GMT+08:00) China Standard Time (Asia/Shanghai)" },
    { id: "8.30", value: "(GMT+08:30) North Korea Time (Asia/Pyongyang)" },
    { id: "9", value: "(GMT+09:00) Japan Standard Time (Asia/Tokyo)" },
    {
      id: "9.30",
      value: "(GMT+09:30) Australia Central Time (Australia/Darwin)"
    },
    {
      id: "10.30",
      value: "(GMT+10:30) Australian Central Daylight Time (Australia/Adelaide)"
    },
    {
      id: "11",
      value: "(GMT+11:00) Lord Howe Daylight Time (Australia/Lord_Howe)"
    },
    { id: "12", value: "(GMT+12:00) Magadan Time (Asia/Kamchatka)" }
  ]);

  useEffect(() => {
    // Update the document title using the browser API
    http.get(`api/settings?user_id=${userData?.user_id}`).then(response => {
      console.log(response.data.send_email_incase_new_msg);
      // if (response?.data?.code == "1") {
      console.log("response", response.send_email_incase_new_msg);
      setTimeZone(response.data.time_zone);
      setdropValue(response.data.send_email_incase_new_msg);
      setRating(response.data.ideas_by_rating);
      // } else if (response?.data?.code == "0") {
      // }
    });
  }, []);

  const [timeZone, setTimeZone] = useState("");
  const [dropValue, setdropValue] = useState("");
  const [rating, setRating] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleTimeChange = event => {
    setTimeZone(event.target.value);
  };
  const handleToggleChange = event => {
    setdropValue(event.target.value);
  };
  const handleRatingChange = event => {
    setRating(event.target.value);
  };
  const handleClick = () => {
    var requestParams = {
      user_id: userData?.user_id,
      settings: [
        {
          companies_per_page: "10"
        },
        {
          ideas_per_page: "10"
        },
        {
          users_per_page: "10"
        },
        {
          send_email_incase_new_msg: dropValue
        },
        {
          time_zone: timeZone
        },
        {
          ideas_by_rating: rating
        }
      ]
    };
    console.log("request parms", requestParams);
    http.post(`api/updatesettings`, requestParams).then(response => {
      console.log('response', response);
      if (response.data == "success") {
        setSuccess('Preferences updated Successfully')
        window.setTimeout(() => {
          setSuccess('')
        }, 3000);
      } else if (response?.data?.code == "0") {
        setError('Preferences is not updated')
        window.setTimeout(() => {
          setError('')
        }, 3000);
      }
    });
  };
  return (
    <div className='content-wrapper'>
      <Grid container>
        {success && <div class="alert alert-success" role="alert" style={{ position: "reletive",fontSize: 15,width: '100%',top: 10,right: 5}}>{success}</div>}
        {error && <div class="alert alert-danger" role="alert" style={{ position: "reletive",fontSize: 15,width: '100%',top: 10,right: 5}}>{error}</div>}
        <Grid md={10} >
          <div className='preference-main'>
            <Grid md={5}>
              <Grid md={12} className="d-flex">
                <CustomDropDown
                  value={timeZone}
                  dropDownArray={timeZoneList}
                  handleChange={handleTimeChange}
                  label='Your time zone:'
                />
              </Grid>
              <Grid md={12}>
                <CustomDropDown
                  value={dropValue}
                  dropDownArray={[
                    { id: 1, value: "No" },
                    { id: 2, value: "Yes" }
                  ]}
                  handleChange={handleToggleChange}
                  label='Send email in case of new message:'
                />
              </Grid>
              <Grid md={12}>
                <CustomDropDown
                  value={rating}
                  dropDownArray={[
                    { id: 5, value: "5" },
                    { id: 4, value: "4" },
                    { id: 3, value: "3" },
                    { id: 2, value: "2" },
                    { id: 1, value: "1" }
                  ]}
                  handleChange={handleRatingChange}
                  label='Rating'
                />
              </Grid>
              <Grid md={12} spacing={0} alignItems="center" justify="center">
                <Box m={2}>
                  <Button variant="contained" size="large" className={classes.button} onClick={handleClick}>Send</Button>
                </Box>
              </Grid>
              {/* <Grid md={5}>
                <Box justifyContent="center">
                  <Button variant='outlined' color='primary' onClick={handleClick} size="large" className={classes.button}>Save</Button>
                </Box>
              </Grid> */}
            </Grid>
          </div>

        </Grid>
        {/* <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        Custom toggle
      </Dropdown.Toggle>
  
      <Dropdown.Menu >
        <Dropdown.Item eventKey="1">Red</Dropdown.Item>
        <Dropdown.Item eventKey="2">Blue</Dropdown.Item>
        <Dropdown.Item eventKey="3" active>
          Orange
        </Dropdown.Item>
        <Dropdown.Item eventKey="1">Red-Orange</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}
      </Grid>
    </div >
  );
}
export default Preferences;
