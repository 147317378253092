import React, { Component } from "react";
import http from "../http-common";
import LoadingOverlay from "react-loading-overlay";
// import { Alert } from 'reactstrap';
import { Alert } from '@material-ui/lab';
import CookiesService from '../services/CookiesService';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

class Profile extends Component {
    constructor(props) {
        super(props);
        var userData = CookiesService.get("userData");
        this.state = {
            user_interest: "",
            first_name: "",
            last_name: "",
            seniority_level: "",
            designation: "",
            direct_phone: "",
            cell_phone: "",
            address_1: "",
            address_2: "",
            city: "",
            state: "",
            postal_code: "",
            country: "",
            twitter_link: "",
            linkedin_url: "",
            description: "",
            technology_responsibilities: "",
            company_name: "",
            my_notes: "",
            user_id: 0,
            isActive: true,
            isAlert: false,
            file_name: '',
            file_data: userData.picture_url,
        };



        // var userData = JSON.parse(sessionStorage.getItem("userData"));
        this.state.user_id = userData.user_id;

        this.onSubmit = this.onSubmit.bind(this)
        this.fileValidate = this.fileValidate.bind(this);

        // this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        var userData = CookiesService.get("userData");
        http.get(`/api/userProfileWithData?user_id=${userData.user_id}`)
            .then((response) => {
                console.log('comonent did mount ', response.data);
                this.setState({
                    user_interest: response.data.user_interest,
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    seniority_level: response.data.seniority_level,
                    designation: response.data.designation,
                    direct_phone: response.data.direct_phone,
                    address_1: response.data.address_1,
                    cell_phone: response.data.cell_phone,
                    address_2: response.data.address_2,
                    city: response.data.city,
                    state: response.data.state,
                    postal_code: response.data.postal_code,
                    country: response.data.country,
                    twitter_link: response.data.twitter_link,
                    linkedin_url: response.data.linkedin_url,
                    description: response.data.description,
                    technology_responsibilities: response.data.technology_responsibilities,
                    company_name: response.data.company_name,
                    my_notes: response.data.my_notes,
                    isActive: false,
                    invalidImage: '',
                    file_name: '',
                    file_data: userData.picture_url,
                });
                //console.log(this.state);
            })
            .catch(err => {
                console.log(err);
            });
    }

    handleChange = (e) => {
        this.state[e.target.name] = e.target.value;
    }

    fileValidate(event) {
        const imageFile = event.target.files[0];
        console.log("imageFile", imageFile);
        if (!imageFile) {
            this.setState({ invalidImage: 'Please select image.' });
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            this.setState({ invalidImage: 'Please select valid image.' });
            return false;
        }
        var reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = (event) => {
            this.state.file_name = imageFile.name;
            this.state.file_data = reader.result;
            console.log("file_data", this.state.file_data);
            this.setState({ invalidImage: null });
        };
    }

    onSubmit(e) {

        e.preventDefault();
        this.setState({ isActive: true });
        var obj = {
            user_id: 104610,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            seniority_level: this.state.seniority_level,
            designation: this.state.designation,
            user_interest: this.state.user_interest,
            direct_phone: this.state.direct_phone,
            cell_phone: this.state.cell_phone,
            address_1: this.state.address_1,
            address_2: this.state.address_2,
            city: this.state.city,
            state: this.state.state,
            postal_code: this.state.postal_code,
            country: this.state.country,
            twitter_link: this.state.twitter_link,
            linkedin_url: this.state.linkedin_url,
            description: this.state.description,
            technology_responsibilities: this.state.technology_responsibilities,
            company_name: this.state.company_name,
            my_notes: this.state.my_notes,
            file_data: this.state.file_data,
            file_name: this.state.file_name
        };
        console.log(obj);
        http.post("/api/profileActionedit", obj)
            .then((response) => {
                console.log("file", response);
                CookiesService.set('userData', response.data, {});
                this.setState({
                    isActive: false,
                    first_name: this.state.first_name,
                    designation: this.state.designation
                });
                this.setState({ isAlert: true }, () => {
                    window.setTimeout(() => {
                        this.setState({ isAlert: false })
                    }, 2000)
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {

        const classes = {
            labelImage: {
                cursor: "pointer",
                position: 'absolute',
                top: '50%',
                left: '55%'
            },
            widthFull: {
                position: "reletive",
                fontSize: 15,
                width: '100%',
                top: 10,
                right: 5
            },
        }
        const { first_name, last_name, designation, user_interest, direct_phone, cell_phone, address_1, address_2, city, state, postal_code, country, twitter_link, linkedin_url, description, technology_responsibilities, company_name, my_notes } = this.state;
        return (
            <div>

                <LoadingOverlay
                    active={this.state.isActive}
                    spinner
                    text='Please wait...'
                >
                    <div class="content-wrapper">
                        {this.state.isAlert && <Alert severity="success" style={classes.widthFull} >Profile has been updated successfully</Alert>}
                        {/* <Alert fade={false} variant="success" isOpen={this.state.isAlert} > Profile has been updated successfully</Alert> */}
                        <section class="content-header">
                            <h1> Profile </h1>
                            <ol class="breadcrumb">
                                <li><a href="#"><i class="fa fa-dashboard"></i> Home</a></li>
                                <li class="active">Profile</li>
                            </ol>
                        </section>
                        <section class="content idea-profilepage">
                            <div class="row">
                                {/* <div class="col-lg-4 col-md-12">
                                    <div class="box box-primary">
                                        <div class="box-body text-center">
                                            <div class="account-head margin-bottom">Your account is being Virified</div>
                                            <div class="text-muted margin-bottom"> if you need to update your information your will be able to request a change after current verification is completed.</div>
                                            <ul class="progressbar">
                                                <li class="active">login</li>
                                                <li>Edit</li>
                                                <li>View</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="box box-primary">
                                        <div class="box-body box-profile">
                                            <div>
                                                <img class="profile-user-img img-responsive img-circle" src={this.state.file_data !== '' ? this.state.file_data : '../assets/images/user5.jpg'} alt="User profile picture" />
                                                <label for="picture" style={classes.labelImage}>
                                                    <CameraAltIcon style={{ fontSize: '25px', color: '#fe7500' }} />
                                                    <input type='file' name='picture' id='picture' style={{ display: "none" }} accept="image/png,image/jpg,image/jpeg" onChange={this.fileValidate} />
                                                    {this.state.invalidImage && <p style={{ color: 'red' }}>{this.state.invalidImage}</p>}
                                                </label>
                                                <h3 class="profile-username text-center">{this.state.first_name}</h3>
                                                <p class="text-muted text-center">{this.state.designation}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div class="col-lg-12 col-md-12">
                                    <div class="box box-primary">
                                        <div class="box-header with-border"> <h3 class="box-title">Personal Details</h3> </div>
                                        <div class="box-body">
                                            <form role="form" onSubmit={this.update}>
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <img class="profile-user-img img-responsive img-circle" src={this.state.file_data !== '' ? this.state.file_data : '../assets/images/user5.jpg'} alt="User profile picture" />
                                                        <label for="picture" style={classes.labelImage}>
                                                            <CameraAltIcon style={{ fontSize: '25px', color: '#fe7500' }} />
                                                            <input type='file' name='picture' id='picture' style={{ display: "none" }} accept="image/png,image/jpg,image/jpeg" onChange={this.fileValidate} />
                                                            {this.state.invalidImage && <p style={{ color: 'red' }}>{this.state.invalidImage}</p>}
                                                        </label>
                                                        <h3 class="profile-username text-center">{this.state.first_name}</h3>
                                                        <p class="text-muted text-center">{this.state.designation}</p>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>First Name</label>
                                                            <input class="form-control" ref="first_name" name="first_name" defaultValue={first_name} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Last Name</label>
                                                            <input class="form-control" ref="last_name" name="last_name" defaultValue={last_name} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Seniority Level</label>
                                                            <select class="form-control select2" styles={"width: 100%;"} ref="seniority_level" name="seniority_level" defaultValue={this.state.seniority_level}>
                                                                <option selected="selected">Project Manager</option>
                                                                <option>Team Lead</option>
                                                                <option>Sr. Marketing Officer</option>
                                                                <option>Sr. Design Excutive</option>
                                                                <option>Chief Technical Officer</option>
                                                                <option>CEO &amp; Managing Director</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Designation</label>
                                                            <input class="form-control" ref="designation" name="designation" defaultValue={designation} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Interest Area</label>
                                                            <input class="form-control" ref="user_interest" name="user_interest" defaultValue={user_interest} onChange={this.handleChange} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Direct Phone</label>
                                                            <input class="form-control" ref="direct_phone" name="direct_phone" defaultValue={direct_phone} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Cell Phone</label>
                                                            <input class="form-control" ref="cell_phone" name="cell_phone" defaultValue={cell_phone} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="box-header with-border margin-bottom"> <h3 class="box-title">Location</h3> </div>
                                                <div class="row margin-bottom">
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Address 1</label>
                                                            <input class="form-control" ref="address_1" name="address_1" defaultValue={address_1} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Address 2</label>
                                                            <input class="form-control" ref="address_2" name="address_2" defaultValue={address_2} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>City</label>
                                                            <input class="form-control" ref="city" name="city" defaultValue={city} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>State</label>
                                                            <input class="form-control" ref="state" name="state" defaultValue={state} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Postal Code</label>
                                                            <input class="form-control" ref="postal_code" name="postal_code" defaultValue={postal_code} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Country</label>
                                                            <input class="form-control" ref="country" name="country" defaultValue={country} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="box-header with-border margin-bottom"> <h3 class="box-title">Social</h3> </div>
                                                <div class="row margin-bottom">
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Twitter Link</label>
                                                            <input class="form-control" ref="twitter_link" name="twitter_link" defaultValue={twitter_link} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>LinkedIn URL</label>
                                                            <input class="form-control" ref="linkedin_url" name="linkedin_url" defaultValue={linkedin_url} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Description</label>
                                                            <input class="form-control" ref="description" name="description" defaultValue={description} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Technology Responsibilites</label>
                                                            <input class="form-control" ref="technology_responsibilities" name="technology_responsibilities" defaultValue={technology_responsibilities} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>Company Name</label>
                                                            <input class="form-control" ref="company_name" name="company_name" defaultValue={company_name} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <div class="form-group">
                                                            <label>My Notes</label>
                                                            <input class="form-control" ref="my_notes" name="my_notes" defaultValue={my_notes} onChange={this.handleChange} placeholder="Enter..." type="text" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p class="text-center margin-bottom"><button type="submit" onClick={this.onSubmit} class="btn send-btn">Update</button></p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </LoadingOverlay>
            </div>
        )
    }
}

export default Profile;