import { Grid, IconButton, Box } from "@material-ui/core";
import React,{ Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import NoDataFound from "../../../NoDataFound";
import SearchBar from "../SearchBar";
import Pagination from 'react-bootstrap/Pagination';
import http from "../../../../http-common";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import DoneIcon from '@material-ui/icons/Done';
import RefreshIcon from '@material-ui/icons/Refresh';

class SelectCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestParams: this.props.location.state.params,
            category_id: 0,
            category_name: '',
            specializationList: [],
            search: "",
            isActive: false,
            totleSpecialization: "0",
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
            paginationIteams: [],
            actualTotalPage: 0,
            noDataFound: false
        }
        this.sendData = this.sendData.bind(this);
        this.selectCategory = this.selectCategory.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.callAPIOnPagination = this.callAPIOnPagination.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.resetsSelectcategory = this.resetsSelectcategory.bind(this);
    }

    sendData() {
        var params = this.state.requestParams;
        console.log('parmas',params);
        this.props.history.push({ pathname: 'post-problem', state: {params}});
    }

    resetsSelectcategory() {
        this.setState({isActive: true});
        this.state.search = '';
        this.state.specializationList = [];
        this.state.paginationIteams = [];
        this.state.category_id = '';
        this.state.category_name = '';
        this.state.requestParams.category_id = '';
        this.state.requestParams.category_name = '';
        this.setState({isActive: false});
    }

    selectCategory(event) {
        this.state.category_id = event.target.value;
        var category = this.state.specializationList.filter((item) => (item.term_id == this.state.category_id));
        this.state.category_name = category[0].name;
        this.state.requestParams.category_id = this.state.category_id;
        this.state.requestParams.category_name = this.state.category_name;
    }

    handlePageClick(event) {
        this.state.currentPage = parseInt(event.target.id);
        this.state.paginationIteams = [];
        this.setState({ isActive: true });
        this.callAPIOnPagination();
    }

    firstPage() {
        if (this.state.currentPage = 1) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    lastPage() {
        if (this.state.currentPage = this.state.actualTotalPage) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    nextPage() {
        if (this.state.currentPage < this.state.actualTotalPage) {
            this.state.currentPage = parseInt(this.state.currentPage) + 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    previousPage() {
        if (this.state.currentPage > 1) {
            this.state.currentPage = parseInt(this.state.currentPage) - 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    handleChangeSearch(newValue) {
        this.state.search = newValue;
        this.state.paginationIteams = [];
        if(this.state.search){
        this.setState({ isActive: true });
    } else {
        this.setState({ isActive: false });
    }
        this.callAPIOnPagination();
    }

    callAPIOnPagination() {
        var params = {
            page: this.state.currentPage,
            per_page: this.state.perPage,
            search: this.state.search
        }
        http.post("api/searchTermsDataList", params)
            .then((response) => {
                if (!response.data.total_count == 0) {
                    this.state.specializationList = response.data.data;
                    console.log(this.state.specializationList);
                    this.state.totleSpecialization = parseInt(response.data.total_count);
                    var total_pages = Math.ceil(this.state.totleSpecialization / this.state.perPage);
                    if (this.state.totleSpecialization > this.state.perPage) {
                        this.state.actualTotalPage = total_pages;

                        this.state.totalPages = (total_pages > 10) ? 10 : total_pages;

                        var newPaginationItems = [];
                        newPaginationItems.push(<Pagination.First onClick={this.firstPage} />,
                            <Pagination.Prev onClick={this.previousPage} />);
                        for (let number = 1; number <= this.state.totalPages; number++) {
                            newPaginationItems.push(
                                <Pagination.Item key={number} active={number === this.state.currentPage} id={number} onClick={this.handlePageClick}>
                                    {number}
                                </Pagination.Item>,
                            );
                        }
                        newPaginationItems.push(<Pagination.Next onClick={this.nextPage} />,
                            <Pagination.Last onClick={this.lastPage} />);
                        this.state.paginationIteams = newPaginationItems;
                    }
                    this.state.noDataFound = false;
                } else {
                    this.state.paginationIteams = [];
                    this.state.specializationList = [];
                    this.state.noDataFound = true;
                }
                this.setState({ isActive: false });
            })
            .catch(err => {
                console.log(err);
            });
    }



    render() {
        const classes = (theme) => ({
            root: {
                width: "100%",
                // maxWidth: "36ch",
                backgroundColor: theme.palette.background.paper
            }
        });
        const customCss = {
            buttonStyle: {
                color: "green",
            },
            sizeIcon: {
                    fontSize: 35,
                    color: "Green"
                },
            refreshIcon: {
                fontSize: 35,
                color: "red"
            },
        }
        return (
            <div class="content-wrapper">
                <Grid container>
                    <Grid md={12}>
                        <SearchBar placeholder="Search Category by try typing 'Company'" onChangeValue={this.handleChangeSearch} />
                        <LoadingOverlay
                            active={this.state.isActive}
                            spinner
                            text='Please wait...'
                        >
                        {this.state.search ? 
                        <>
                        <Grid md={12} className='d-flex'>
                            <Grid md={12}>
                                <h1>Select Category</h1>
                            </Grid>
                            <Grid md={1}>
                                <IconButton style={customCss.buttonStyle} onClick={this.sendData}><DoneIcon style={customCss.sizeIcon} /> </IconButton>
                            </Grid>
                            <Grid md={1}>
                                <IconButton style={customCss.buttonStyle} onClick={this.resetsSelectcategory}><RefreshIcon style={customCss.refreshIcon} /> </IconButton>
                            </Grid>
                        </Grid>
                        <List className={classes.root}>
                                {this.state.specializationList.map((item, index) => (
                                    <>
                                        <ListItem alignItems='flex-start' className='service-list'>
                                            <ListItemText
                                                //   primary='Brunch this weekend?'
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            component='span'
                                                            variant='body2'
                                                            className={classes.inline}
                                                            color='textPrimary'>
                                                            <span>{item?.name}</span>
                                                        </Typography>
                                                        <input type='radio' name='category_name' value={item.term_id} onClick={this.selectCategory}/>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        <Divider component='li' />

                                    </>
                                ))
                            }
                        </List>
                        </>
                        : <NoDataFound message='Search Category' icon='fa fa-search' />}
                        {this.state.paginationIteams &&
                            <Grid className="d-flex">
                                <Box justifyContent="center" style={{ margin: "auto" }}>
                                    <Pagination>{this.state.paginationIteams}</Pagination>
                                </Box>
                            </Grid>
                        }
                        {this.state.noDataFound && <NoDataFound message='No record found' icon='fa fa-search' />}
                        </LoadingOverlay>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default SelectCategory;