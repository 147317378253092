import { Component } from "react";
import { Box, Avatar, Grid, Button, FormGroup, FormControlLabel  } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import HeaderBizofit from "./HeaderBizofit";
import CookiesService from '../../services/CookiesService';
import http from "../../http-common";

class LoginDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            googleData: this.props.location.state?.param,
            firstName: '',
            lastName: '',
            email: '',
            pictureUrl: '',
            googleId: '',
            companyName: '',
            designation: '',
            termCondition: 0,
            error: '',
            success: '',
        }
        this.state.firstName = this.state.googleData.first_name;
        this.state.lastName = this.state.googleData.last_name;
        this.state.email = this.state.googleData.email;
        this.state.googleId = this.state.googleData.googleId;
        this.state.pictureUrl = this.state.googleData.picture_url;
        console.log('google data in login page', this.state.googleData);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleData = this.handleData.bind(this);
        this.handleChaeckBox = this.handleChaeckBox.bind(this);
    }

    handleSubmit() {
        if(this.state.termCondition == 1) {
            if(this.state.companyName !== '' && this.state.designation !== ''){
                var param = {
                    id: this.state.googleId,
                    picture_url: this.state.pictureUrl,
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    email: this.state.email,
                    company_name: this.state.companyName,
                    designation: this.state.designation,
                }
                console.log('parma for signin api', param);
                http.post("/api/checkLinkedInLogin", param)
                    .then((response) => {
                        console.log('response', response);
                        var userData = response?.data;
                        CookiesService.set("userData", JSON.stringify(userData), {});
                        this.props.history.push({ pathname: '/youtube-step', state: {  } });
                    });
            } else{
                this.setState({ error: 'Company and designation field are require' }, () => {
                    window.setTimeout(() => {
                        this.setState({ error: '' });
                    }, 3000)
                });
            }
            
        } else {
            this.setState({ error: 'You have to accept terms and conditions' }, () => {
                window.setTimeout(() => {
                    this.setState({ error: '' });
                }, 3000)
            });
        }
    }

    handleData(e) {
        this.state[e.target.name] = e.target.value;
    }

    handleChaeckBox(e) {
        if(e.target.checked) {
            this.state.termCondition = 1;
        } else {
            this.state.termCondition = 0;
        }
    }

    render() {
        const classes = {
            widthFull: {
                position: "reletive",
                fontSize: 15,
                width: '100%',
                top: 10,
                right: 5
            },
            textOrange: {
                position: 'relative',
                color: '#ff7300',
            },
            textBlue: {
                color: '#3d6c8c',
            },
            textBluelable: {
                color: '#3d6c8c',
                fontSize: '25px'
            },
            checkBoxStyle: {
                color: '#ff7300',
            },
            buttonStyle: {
                backgroundColor: "#ff720a",
                color: "#ffffff",
                textTransform: 'none',
                width: '100%',
                fontSize: '18px'
            }
        }
        return (
            <>
            <Grid container justify="center" >
                
            {this.state.success && <div className="alert alert-success" role="alert" style={classes.widthFull}>{this.state.success}</div>}
                        {this.state.error && <div className="alert alert-danger" role="alert" style={classes.widthFull}>{this.state.error}</div>}
                <Grid md={6}>
                <HeaderBizofit />
                <Box boxShadow={5} borderRadius={10} p={2}>
                    <Grid md={12}>
                        <Box css={{textAlign: 'center'}}><h2 style={classes.textOrange}>Profile Details</h2></Box>
                        <Grid className='d-flex'>
                            <Grid md={6}>
                                <h4 style={classes.textBlue}>Email:</h4>
                            </Grid>
                            <Grid md={6}>
                                <h4>{this.state.email}</h4>
                            </Grid>
                        </Grid>
                        <Grid className='d-flex'>
                            <Grid md={6}>
                                <h4 style={classes.textBlue}>First Name:</h4>
                            </Grid>
                            <Grid md={6}>
                                <h4>{this.state.firstName}</h4>
                            </Grid>
                        </Grid>
                        <Grid className='d-flex'>
                            <Grid md={6}>
                                <h4 style={classes.textBlue}>Last Name:</h4>
                            </Grid>
                            <Grid md={6}>
                                <h4>{this.state.lastName}</h4>
                            </Grid>
                        </Grid>
                        <Grid className='d-flex'>
                            <Grid md={6}>
                                <h4 style={classes.textBlue}>Company:</h4>
                            </Grid>
                            <Grid md={6}>
                                <input type='text' name='companyName' onChange={this.handleData} required />
                            </Grid>
                        </Grid>
                        <Grid className='d-flex'>
                            <Grid md={6}>
                                <h4 style={classes.textBlue}>Designation:</h4>
                            </Grid>
                            <Grid md={6}>
                                <input type='text' name='designation' onChange={this.handleData} required />
                            </Grid>
                        </Grid>
                        <Grid className='d-flex'>
                            <Grid md={6}>
                                <h4 style={classes.textBlue}>Terms and Conditions</h4>
                            </Grid>
                            <Grid md={6}>
                                <FormControlLabel
                                        control={
                                            <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="large" style={classes.checkBoxStyle} />}
                                            checkedIcon={<CheckBoxIcon fontSize="large" style={classes.checkBoxStyle} />}
                                            name="termCondition"
                                            onChange={this.handleChaeckBox}
                                            value='1'
                                        />
                                        }
                                        label={<span style={{fontSize: '15px'}}>I accept the Terms and Conditions</span>}
                                        style={classes.textBluelable}
                                    />
                            </Grid>
                        </Grid>
                        <Grid className='d-flex'>
                            <Grid md={3}></Grid>
                            <Grid md={6}>
                                <Button style={classes.buttonStyle} onClick={this.handleSubmit}>Continue</Button>
                            </Grid>
                            <Grid md={3}></Grid>
                        </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>
            </>
        );
    }
}

export default LoginDetail;