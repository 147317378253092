import React, { Component } from "react";

class Header extends Component {
    render() {
        return (
            <div>
                <header class="main-header">
                    <a href="#" class="logo">
                        {/* <!-- mini logo for sidebar mini 50x50 pixels --> */}
                        <span class="logo-mini">HQ</span>
                        {/* <!-- logo for regular state and mobile devices --> */}
                        <span class="logo-lg">Innovation HQ</span>
                    </a>
                    <nav class="navbar navbar-static-top" role="navigation">
                        <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
                            <span class="sr-only">Toggle navigation</span>
                        </a>
                        {/* <div class="navbar-custom-menu">
                            <ul class="nav navbar-nav">
                                <li class="dropdown messages-menu">
                                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                                        <i class="fa fa-envelope-o"></i>
                                        <span class="label label-success">4</span>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="header">You have 4 messages</li>
                                        <li>
                                            <ul class="menu">
                                                <li>
                                                    <a href="#">
                                                        <div class="pull-left">
                                                            <img src={"../assets/images/user2.jpg"} class="img-circle" alt="User Image" />
                                                        </div>
                                                        <h4>Support Team<small><i class="fa fa-clock-o"></i> 5 mins</small></h4>
                                                        <p>Lorem Ipsum dummy text</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="footer"><a href="#">See All Messages</a></li>
                                    </ul>
                                </li>
                                <li class="dropdown notifications-menu">
                                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                                        <i class="fa fa-bell-o"></i>
                                        <span class="label label-warning">10</span>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="header">You have 10 notifications</li>
                                        <li>
                                            <ul class="menu">
                                                <li><a href="#"><i class="fa fa-users text-aqua"></i> 5 new members joined today</a></li>
                                            </ul>
                                        </li>
                                        <li class="footer"><a href="#">View all</a></li>
                                    </ul>
                                </li>
                                <li class="dropdown user user-menu">
                                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                                        <img src={"../assets/images/user2.jpg"} class="user-image" alt="User Image" />
                                        <span class="hidden-xs">Jems</span>
                                    </a>
                                    <ul class="dropdown-menu">
                                        The user image in the menu
                                        <li class="user-header">
                                            <img src={"../assets/images/user2.jpg"} class="img-circle" alt="User Image" />
                                            <p>Jems - Web Developer<small>Member since Nov. 2012</small></p>
                                        </li>
                                         Menu Body
                                        <li class="user-body">
                                            <div class="row">
                                                <div class="col-xs-4 text-center">
                                                    <a href="#">Followers</a>
                                                </div>
                                                <div class="col-xs-4 text-center">
                                                    <a href="#">Sales</a>
                                                </div>
                                                <div class="col-xs-4 text-center">
                                                    <a href="#">Friends</a>
                                                </div>
                                            </div>
                                             /.row
                                        </li>
                                         Menu Footer
                                        <li class="user-footer">
                                            <div class="pull-left">
                                                <a href="#" class="btn btn-default btn-flat">Profile</a>
                                            </div>
                                            <div class="pull-right">
                                                <a href="#" class="btn btn-default btn-flat">Sign out</a>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                 Control Sidebar Toggle Button
                                <li><a href="#" data-toggle="control-sidebar"><i class="fa fa-gears"></i></a></li>
                            </ul>
                        </div> */}
                    </nav>
                </header>
                {/* <aside class="control-sidebar control-sidebar-dark">
                    <div class="tab-content">
                        <div class="tab-pane active" id="control-sidebar-home-tab">
                            <h3 class="control-sidebar-heading">Recent Activity</h3>
                            <ul class="control-sidebar-menu">
                                <li>
                                    <a href="javascript:;"><i class="menu-icon fa fa-birthday-cake bg-red"></i>
                                        <div class="menu-info">
                                            <h4 class="control-sidebar-subheading">Langdon's Birthday</h4>
                                            <p>Will be 23 on April 24th</p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </aside>
                <div class="control-sidebar-bg"></div> */}
            </div>
        )
    }
}

export default Header;