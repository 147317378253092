import React, {Component} from 'react';
import Pagination from 'react-bootstrap/Pagination';
import http from "../../../../http-common";
import { Box, Avatar, Grid, Button, IconButton} from "@material-ui/core";
import Rating from "../../../view/formComponent/rating";
import ShareIcon from "@material-ui/icons/Share";
import { withRouter } from 'react-router-dom';
import SearchBar from '../SearchBar';
import moment from 'moment';    
import LoadingOverlay from "react-loading-overlay";
import CircularProgress from '@material-ui/core/CircularProgress';
import NoDataFound from "../../../NoDataFound";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CreateIcon from '@material-ui/icons/Create';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

class ProblemStatementsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            problem_id: 0,
            problemList: [],
            search: "",
            isActive: true,
            totalCompany: "0",
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
            paginationIteams: [],
            actualTotalPage: 0,
            noDataFound: false,
            is_select_date: false,
            upto_deadline_date: '',
            DeadLine: moment(new Date()).format("DD-MMM-YYYY"),
        }
        this.handlePageClick = this.handlePageClick.bind(this);
        this.callAPIOnPagination = this.callAPIOnPagination.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.handelCalender = this.handelCalender.bind(this);
        this.handelDateSelect = this.handelDateSelect.bind(this);
        this.callFilterApi = this.callFilterApi.bind(this);
    }

    callFilterApi() {
        this.state.upto_deadline_date = moment(this.state.DeadLine).format("YYYY-MM-DD");
        this.state.is_select_date = false;
        this.setState({isActive: true});
        this.callAPIOnPagination();
    }

    handelDateSelect(value, event) {
        var date = moment(value).format("DD-MMM-YYYY");
        this.setState({DeadLine : date});
    }

    handelCalender() {
        this.setState({is_select_date : !this.state.is_select_date});
    }

    handleChangeSearch(newValue) {
        this.state.search = newValue;
        this.state.paginationIteams = [];
        this.setState({ isActive: true });
        this.callAPIOnPagination();
    }

    handlePageClick(event) {
        console.log(event);
        //console.log(Number(event.target.id));
        this.state.currentPage = parseInt(event.target.id);
        //this.setState({currentPage:parseInt(event.target.id)});
        this.state.paginationIteams = [];
        this.setState({ isActive: true });
        this.callAPIOnPagination();
    }

    callAPIOnPagination() {
        http.get(`api/getAllProblemsWithCompany?page=${this.state.currentPage}&per_page=${this.state.perPage}&search=${this.state.search}&upto_deadline_date=${this.state.upto_deadline_date}`)
            .then((response) => {
                console.log(response);
                if (response.data.total_count) {
                    
                    this.state.problemList = response.data.problems_list;
                    console.log('problemList', this.state.problemList);
                    this.state.totalCompany = parseInt(response.data.total_count);
                    var total_pages = Math.ceil(this.state.totalCompany / this.state.perPage);
                    if (this.state.totalCompany > this.state.perPage) {
                        this.state.actualTotalPage = total_pages;

                        this.state.totalPages = (total_pages > 10) ? 10 : total_pages;

                        var newPaginationItems = [];
                        newPaginationItems.push(<Pagination.First onClick={this.firstPage} />,
                            <Pagination.Prev onClick={this.previousPage} />);
                        for (let number = 1; number <= this.state.totalPages; number++) {
                            newPaginationItems.push(
                                <Pagination.Item key={number} active={number === this.state.currentPage} id={number} onClick={this.handlePageClick}>
                                    {number}
                                </Pagination.Item>,
                            );
                        }
                        newPaginationItems.push(<Pagination.Next onClick={this.nextPage} />,
                            <Pagination.Last onClick={this.lastPage} />);
                        this.state.paginationIteams = newPaginationItems;
                    }
                    this.state.noDataFound = false;
                } else {
                    this.state.problemList = [];
                    this.state.paginationIteams = [];
                    this.state.noDataFound = true;
                }
                this.setState({ isActive: false });
                //console.log(this.state);
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.callAPIOnPagination();
    }

    firstPage() {
        if (this.state.currentPage = 1) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    lastPage() {
        if (this.state.currentPage = this.state.actualTotalPage) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    nextPage() {
        if (this.state.currentPage < this.state.actualTotalPage) {
            this.state.currentPage = parseInt(this.state.currentPage) + 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    previousPage() {
        if (this.state.currentPage > 1) {
            this.state.currentPage = parseInt(this.state.currentPage) - 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    render() {
        const classes = {
            calenderCss: {
                position: 'absolute',
                marginTop: '-28px',
                marginLeft: '-25%',
                zIndex: 1,
                backgroundColor: '#fff',
            },
            buttonStyle: {
                color: '#ff7300',
            }
        }
        return (
            <div className='content-wrapper'>
            <LoadingOverlay
                active={this.state.isActive}
                spinner
                text='Please wait...'
            >
                <SearchBar placeholder="Search Category by try typing 'Fintech'" onChangeValue={this.handleChangeSearch} />
                <Grid container>
                <Grid md={12} className='d-flex' justify="flex-end">                                
                                <Grid>
                                    <IconButton style={classes.buttonStyle} onClick={this.handelCalender} title="Filter By Deadline" ><CalendarTodayIcon fontSize="large" /></IconButton>
                                    {this.state.is_select_date && 
                                    <Box boxShadow={8} style={classes.calenderCss}> 
                                        <Calendar onChange={this.handelDateSelect} maxDate={new Date()} defaultValue={new Date('"'+this.state.DeadLine+'"')}/>
                                        <Button variant="outlined" color="primary" onClick={this.callFilterApi} style={{left: '30%'}}>Ok</Button>
                                        <Button variant="outlined" color="primary" onClick={this.handelCalender} style={{left: '42%'}}>Cencel</Button>
                                    </Box>}
                                </Grid>
                                <Grid><IconButton style={classes.buttonStyle} onClick={() => { this.props.history.push({ pathname: '/post-problem'}) }} title="Post A Problem"><CreateIcon fontSize="large" /></IconButton></Grid>
                            </Grid>
                    <Grid md={12}>
                        {this.state.problemList.map((item, index) => (
                            <Box boxShadow={2} bgcolor='background.paper' m={1} p={1} key={item?.id} onClick={() => { this.props.history.push({ pathname: '/problem-statement-detail', state: { problem_id: item?.problem_id, user_id: item?.user_id } }) }}>
                                <Grid md={12} className='d-flex'>
                                    <Grid style={{ margin: "auto" }}>
                                        <Avatar
                                            alt='Remy Sharp'
                                            src={item?.picture_url}
                                            style={{ width: "95px", height: "95px" }}
                                        />
                                    </Grid>
                                    <Grid md={11}>
                                        <Box pl={2}>
                                            <h4>{item?.username}</h4>
                                            <p>{item?.company_name}</p>
                                            <p style={{ color: "#ff7a0c" }}>{item?.challenge_type}</p>
                                            <p>{item?.description.substr(0, 100) + "..."}</p>
                                            <p> {moment.unix(item?.date_created).format("DD-MMM-YYYY hh:mm:s")} </p>
                                            <p>({item?.category})</p>
                                            <p style={{ color: "#ff7a0c" }}>DadeLine: {moment(item?.deadline_date).format("DD-MMM-YYYY")}</p>
                                        </Box>
                                    </Grid>
                                    <Grid md={1} style={{ margin: 'auto' }}>
                                        <div className="solution-wrap">
                                            <Button variant="contained" style={{ backgroundColor: "#ff720a", color: "#ffffff", textTransform: 'none' }} size="large">{item?.problem_ideas_count} Solutions</Button>
                                        </div>
                                    </Grid>
                                    <Grid md={1} style={{ margin: 'auto' }}>
                                        <div className='icon-wrap'>
                                            <ShareIcon fontSize="large" style={{ color: "#ff720a" }} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                        {this.state.paginationIteams &&
                            <Grid className="d-flex">
                                <Box justifyContent="center" style={{ margin: "auto" }}>
                                    <Pagination>{this.state.paginationIteams}</Pagination>
                                </Box>
                            </Grid>
                        }
                        {this.state.noDataFound == true && <NoDataFound message='No record found' icon='fa fa-search' />}
                    </Grid>
                </Grid >
            </LoadingOverlay>
        </div >
        );
    }
}

export default ProblemStatementsList;