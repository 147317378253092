import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, InputBase, IconButton } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderRadius: "50px"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: 15,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function SearchBar(props) {
    const classes = useStyles();

    return (
        <Box bgcolor="#25345a" color="primary.contrastText" p={2}>
            <Paper component="form" className={classes.root}>
                <IconButton type="submit" className={classes.iconButton} aria-label="search">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    className={classes.input}
                    placeholder={props.placeholder}
                    inputProps={{ 'aria-label': 'search google maps' }}
                    onChange={(event) => props.onChangeValue(event.target.value)}
                />
            </Paper>
        </Box>

    );
}