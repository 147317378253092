import { Box, Grid } from '@material-ui/core';
import React, {Component} from 'react';
import StarRatings from 'react-star-ratings';

class ProductCard extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <Box borderRadius={10} boxShadow={2} m={2} p={1}>
                    <Grid className='d-flex'>
                        <Grid md={2}>
                            <img src={this.props.product.attachment} height='100%' width='100%'/>
                        </Grid>
                        <Grid md={12}>
                            <h3>{this.props.product.post_title.substr(0, 50) + "..."}</h3>
                            <p style={{color: '#fe7500', fontSize: 20}}>$ {this.props.product.price}</p>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        )
    }
}

export default ProductCard;