import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Grid, Box} from '@material-ui/core';
import http from "../http-common";
import LoadingOverlay from "react-loading-overlay";
import moment from 'moment';
import Pagination from 'react-bootstrap/Pagination'
import { Card, CardText, CardBody, CardTitle } from 'reactstrap';
import YouTube from 'react-youtube';
import StarRatings from 'react-star-ratings';
import CookiesService from '../services/CookiesService';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ForumIcon from '@material-ui/icons/Forum';

class Idea_submit_new extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: 0,
            ideas: [],
            ideaDetail: {},
            isIdeaDetail: false,
            isActive: true,
            ideas_received_count: "0",
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
            paginationIteams: [],
            actualTotalPage: 0,
            selectedTab: 0,
            isPostMessage: false,
            file_name: '',
            file_data: '',
            invalidImage: '',
            postMessageIdeaId: 0,
            postMessageDescription: '',
            success: '',
            error: '',
        };
        var userData = CookiesService.get("userData");
        // var userData = JSON.parse(sessionStorage.getItem("userData"));
        this.reader = new FileReader();
        this.state.user_id = userData.user_id;
        this.handlePageClick = this.handlePageClick.bind(this);
        this.callAPIOnPagination = this.callAPIOnPagination.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.tabChange = this.tabChange.bind(this);
        this.handlePostMessage = this.handlePostMessage.bind(this);
        this.fileValidate = this.fileValidate.bind(this);
        this.handlePostMessageDescription = this.handlePostMessageDescription.bind(this);
        this.submitPostMessage = this.submitPostMessage.bind(this);
        this.ideaDetailApi = this.ideaDetailApi.bind(this);
    }

    ideaDetailApi(ideaId){
        this.setState({isActive: true});
        http.get(`/api/companyInteractionAction?idea_id=${ideaId}&user_id=${this.state.user_id}`)
            .then((response) => {
                
                this.state.ideaDetail = response.data;
                this.state.isIdeaDetail = true;
                console.log('ideadetail',this.state.ideaDetail);
                this.setState({isActive: false});
            });
    }

    handlePostMessageDescription(event) {
        this.state.postMessageDescription = event.target.value;
    }

    submitPostMessage() {
        this.setState({isActive: true});
        var param = {
            idea_id: this.state.postMessageIdeaId,
            message: this.state.postMessageDescription,
            user_id: this.state.user_id,
            file_name: this.state.file_name,
            file_data: this.state.file_data,
        }
        console.log('post message param', param);
        http.post("/api/ideaInteraction_ios", param)
            .then((response) => {
                console.log('post message responce', response);
                
                if(response.data.status == "success") {
                    this.setState({isActive: false});
                    this.ideaDetailApi(this.state.postMessageIdeaId);
                    this.setState({ success: response.data.message }, () => {
                        window.setTimeout(() => {
                            this.setState({ success: '' });
                        }, 3000)
                    });
                }
            });
    }

    handlePostMessage(ideaId) {
        this.state.postMessageIdeaId = ideaId;
        console.log('postMessageIdeaId', this.state.postMessageIdeaId);
        this.setState({isPostMessage: true});
    }

    tabChange(tab) {
        this.setState({ selectedTab: tab});
    }

    handlePageClick(event) {
        console.log(event);
        this.state.currentPage = parseInt(event.target.id);
        this.state.paginationIteams = [];
        this.callAPIOnPagination();
    }

    callAPIOnPagination() {
        var params = {
            user_id: this.state.user_id,
            page: this.state.currentPage,
            per_page: this.state.perPage
        }
        http.post("/api/sendideasByuserID", params)
            .then((response) => {
                this.state.ideas = response.data.send_ideas;

                console.log(this.state.ideas);
                this.state.ideas_received_count = parseInt(response.data.ideas_received_count);
                var total_pages = Math.ceil(this.state.ideas_received_count / this.state.perPage);

                this.state.actualTotalPage = total_pages;

                this.state.totalPages = (total_pages > 10) ? 10 : total_pages;

                var newPaginationItems = [];
                newPaginationItems.push(<Pagination.First onClick={this.firstPage} />,
                    <Pagination.Prev onClick={this.previousPage} />);
                for (let number = 1; number <= this.state.totalPages; number++) {
                    newPaginationItems.push(
                        <Pagination.Item key={number} active={number === this.state.currentPage} id={number} onClick={this.handlePageClick}>
                            {number}
                        </Pagination.Item>,
                    );
                }
                newPaginationItems.push(<Pagination.Next onClick={this.nextPage} />,
                    <Pagination.Last onClick={this.lastPage} />);

                this.setState({ paginationIteams: newPaginationItems });
                this.setState({ isActive: false });
                this.ideaDetailApi(this.state.ideas[0].idea_id);
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.callAPIOnPagination();
    }

    firstPage() {
        if (this.state.currentPage = 1) {
            this.state.paginationIteams = [];
            this.callAPIOnPagination();
        }
    }

    lastPage() {
        if (this.state.currentPage = this.state.actualTotalPage) {
            this.state.paginationIteams = [];
            this.callAPIOnPagination();
        }
    }

    nextPage() {
        if (this.state.currentPage < this.state.actualTotalPage) {
            this.state.currentPage = parseInt(this.state.currentPage) + 1;
            this.state.paginationIteams = [];
            this.callAPIOnPagination();
        }
    }

    previousPage() {
        if (this.state.currentPage > 1) {
            this.state.currentPage = parseInt(this.state.currentPage) - 1;
            this.state.paginationIteams = [];
            this.callAPIOnPagination();
        }
    }

    fileValidate(event) {
        const imageFile = event.target.files[0];

        if (!imageFile) {
            this.setState({ invalidImage: 'Please select image.' });
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            this.setState({ invalidImage: 'Please select valid image.' });
            return false;
        }

        this.reader.readAsDataURL(imageFile);
        this.reader.onload = (e) => {
            this.state.file_name = imageFile.name;
            this.state.file_data = this.reader.result;
            this.setState({ invalidImage: null });
        };
    }

    render() {
        const opts = {
            height: '290px',
            width: '630px'
        };
        const classes = {
            listStyle: {
                display: 'flex',
            },
            iconStyle: {
                color: '#fe7500',
                fontSize: '20px'
            },
            textOrenge: {
                color: "#fe7500",
                marginTop: "8px",
                position: 'absolute'
            },
            sizeIcon: {
                fontSize: 35,
                color: "#fe7500"
            },
        }

        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    spinner
                    text='Please wait...'
                >
                    <div class="content-wrapper">
                        <section class="content-header">
                            <h1> Idea Submitted </h1>
                            <ol class="breadcrumb">
                                <li><a href="#"><i class="fa fa-dashboard"></i> Home</a></li>
                                <li class="active">Idea Submitted</li>
                            </ol>
                        </section>
                        {this.state.success && <div class="alert alert-success" role="alert" style={classes.widthFull}>{this.state.success}</div>}
                        {this.state.error && <div class="alert alert-danger" role="alert" style={classes.widthFull}>{this.state.error}</div>}
                        {/* Main content */}
                        <Tabs>
                            <section class="content container-fluid">
                                <div id="proinner-page" class="idea-submitpage">
                                    <TabList style={{width: '100%'}}>
                                        <ul class="resp-tabs-list hor_1 widget-user-2" style={{width:'75%'}}>
                                            {this.state.ideas.map((idea, index) =>
                                                <li style={{width: '100%'}} key={index}>
                                                    <Box boxShadow={3} borderRadius={5} p={2} onClick={this.ideaDetailApi.bind('ideaId', idea.idea_id)}>
                                                    <div class="widget-user-image"> <img class="img-circle" src={idea.ideas[0].picture_url} alt="User Avatar" /></div>
                                                    <h3 class="widget-user-username">{idea.ideas[0].username}</h3>
                                                    <h5 class="widget-user-desc">{idea.ideas[0].is_cool_innovation == "1" ? <span style={{color: '#ff7300'}}>{idea.ideas[0].idea_title}</span> : <span>{idea.ideas[0].idea_title}</span>}</h5>
                                                    
                                                    <h5 class="widget-user-desc">{idea.ideas[0].description}</h5>
                                                    <div class="time"> <span class="fa fa-clock-o"></span>{moment.unix(idea.ideas[0].date_created).format("DD-MMM-YYYY hh:mm:s")} </div>
                                                    <ul style={classes.listStyle}>
                                                        <li style={classes.iconStyle}><i class="fa fa-heart-o"></i> {idea.like_count}</li>
                                                        <li style={classes.iconStyle}><i class="fa fa-thumbs-o-down"></i> {idea.dis_like_count}</li>
                                                        <li style={classes.iconStyle}><i class="fa fa-reply"></i> {idea.replied_count}</li>
                                                        <li style={classes.iconStyle}><i class="fa fa-share"></i> {idea.ideas[0].is_forward}</li>
                                                    </ul>
                                                    </Box>
                                                </li>)}
                                        </ul>
                                    </TabList>
                                    <div class="resp-tabs-container hor_1 widget-user-2">
                                    {this.state.isIdeaDetail == true ? 
                                    <Box>
                                            <div>
                                                <div class="widget-user-header pos-relative">
                                                <span data-toggle="modal" data-target="#post-message" class="fa fa-pencil edit-idea" onClick={this.handlePostMessage.bind('ideaId', this.state.ideaDetail.idea.idea_id)} style={{color: '#ff7300'}}></span>
                                                    <div class="widget-user-image">
                                                        <img class="img-circle" src={this.state.ideaDetail.idea.picture_url} alt="User Avatar" />
                                                    </div>
                                                    <h3 class="widget-user-desc to">To </h3>
                                                    <h3 class="widget-user-username">{this.state.ideaDetail.idea.username}</h3>
                                                    <h5 class="widget-user-desc">{this.state.ideaDetail.idea.is_cool_innovation == "1" ? <span style={{color: '#ff7300'}}>{this.state.ideaDetail.idea.company_name}'s {this.state.ideaDetail.idea.designation}</span> : <span>{this.state.ideaDetail.idea.company_name}'s {this.state.ideaDetail.idea.designation}</span>}</h5>
                                                    <div class="time"> <span class="fa fa-clock-o"></span> {moment.unix(this.state.ideaDetail.idea.date_created).format("DD-MMM-YYYY hh:mm:s")}  </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="box-body">
                                                    <p>{this.state.ideaDetail.idea.description}</p>
                                                </div>
                                            </div>                                            
                                            <div>
                                                {this.state.ideaDetail.idea.is_company_shared == "1" ? (
                                                    <>
                                                    <Grid md={12}>
                                                        <Box boxShadow={5} p={2} m={2} borderRadius={10} onClick={() => { this.props.history.push({ pathname: '/company-detail', state: { post_id: this.state.ideaDetail.idea.company_post_id } }) }}>
                                                            {/* <h4>{idea.receiver[0].receiver_data[0].username}</h4> */}
                                                            <Grid><h3>{this.state.ideaDetail.idea.share_company_name}</h3></Grid>
                                                            <Grid>
                                                                <StarRatings
                                                                    rating={parseFloat(this.state.ideaDetail.idea.company_avg_rating)}
                                                                    starRatedColor="#f28a16"
                                                                    starDimension="20px"
                                                                    numberOfStars={5}
                                                                    name=''
                                                                />
                                                            </Grid>
                                                            <Grid><p>{this.state.ideaDetail.idea.company_description.substr(0, 280)+'...'}</p></Grid>
                                                        </Box>
                                                    </Grid>
                                                    </>
                                                ) : null
                                                }
                                            </div>
                                            <div>
                                                {this.state.ideaDetail.idea.is_product_shared == "1" ? (
                                                    <>
                                                    <Grid md={12}>
                                                        <Box boxShadow={5} p={2} m={2} borderRadius={10} onClick={() => { this.props.history.push({ pathname: '/product-detail', state: { product_id: this.state.ideaDetail.idea.product_id } }) }}>
                                                            {/* <h4>{idea.receiver[0].receiver_data[0].username}</h4> */}
                                                            <Grid className='d-flex'>
                                                                <Grid md={2}>
                                                                    <Box mt={2} pr={1}>
                                                                        <img src={this.state.ideaDetail.idea.product_image} width="100%" />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid md={10}>
                                                                    <h3>{this.state.ideaDetail.idea.product_name}</h3>
                                                                    <p>{this.state.ideaDetail.idea.product_description.substr(0, 280)+ '...'}</p>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                    </>
                                                ) : null
                                                }
                                            </div>
                                            {this.state.ideaDetail.idea.is_youtube_data == 1 ?
                                            <>
                                            <Box boxShadow={5} p={2} m={2} borderRadius={10}>
                                                <Grid className='d-flex'>
                                                    <Grid md={3}>
                                                    <img height="100px" width="150px" src={this.state.ideaDetail.idea.youtube_data.image} alt="Card image cap" />
                                                    </Grid>
                                                    <Grid md={8}>
                                                        <h3>{this.state.ideaDetail.idea.youtube_data.title}</h3>
                                                        <p>{this.state.ideaDetail.idea.youtube_data.description.substr(0, 250) + "..."}</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid>
                                                <YouTube
                                                        videoId={this.state.ideaDetail.idea.youtube_data.video_id}                  // defaults -> null
                                                        // id={string}                       // defaults -> null
                                                        // className={string}                // defaults -> null
                                                        // containerClassName={string}       // defaults -> ''
                                                        opts={opts}                        // defaults -> {}
                                                    // onReady={func}                    // defaults -> noop
                                                    // onPlay={func}                     // defaults -> noop
                                                    // onPause={func}                    // defaults -> noop
                                                    // onEnd={func}                      // defaults -> noop
                                                    // onError={func}                    // defaults -> noop
                                                    // onStateChange={func}              // defaults -> noop
                                                    // onPlaybackRateChange={func}       // defaults -> noop
                                                    // onPlaybackQualityChange={func}    // defaults -> noop
                                                    />
                                                </Grid>
                                            </Box>
                                                </>
                                                : null}

                                            {this.state.ideaDetail.messages.length !== 0 ? 
                                                <>
                                                <h3 style={{color: '#ff7300', marginLeft: '20px'}}>Messages:</h3>
                                                {this.state.ideaDetail.messages.map((message, index) => (<Box boxShadow={5} p={2} m={2} borderRadius={10} key={index}>
                                                    <Grid className='d-flex'>
                                                    <Grid md={3}><ForumIcon style={{color: '#ff7300', fontSize: '65px'}}/></Grid>
                                                    <Grid md={12}>
                                                        <p>From <b>{message.from_user == this.state.user_id ? 'me' : message.from_username}</b> to <b>{message.to_user == this.state.user_id ? 'me' : message.to_username}</b></p>
                                                        <p>Message: <span>{message.message}</span></p>
                                                        <p>Test</p>
                                                        <p>{moment.unix(message.date).format("DD-MMM-YYYY hh:mm:s")}</p>
                                                    </Grid>
                                                    </Grid>
                                                </Box>))}
                                                </>
                                                : null}

                                        </Box>: null}
                                    </div>
                                </div>
                            </section>
                        </Tabs>

                        <Grid style={{position: 'relative', left: '15%'}}>
                            <Pagination>{this.state.paginationIteams}</Pagination>
                        </Grid>
                    </div>
                    {this.state.isPostMessage && <div class="modal fade post-modal" id="post-message">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div> Post a Message </div>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
                                </div>
                                <div class="form">
                                    <div class="form-group margin-bottom">
                                        <textarea class="form-control" placeholder="Post a message" name="postMessageDescription" onChange={this.handlePostMessageDescription}></textarea>
                                    </div>
                                    <Grid className='d-flex' md={12}>
                                        <Grid md={6}>
                                    <label for="attachment" style={classes.arrow}>
                                        <AttachFileIcon style={classes.sizeIcon}></AttachFileIcon><span style={classes.textOrenge}>attachment</span>
                                        {this.state.invalidImage && <p style={classes.errorMessage}>{this.state.invalidImage}</p>}
                                        <input type="file" name="attachment" id="attachment" style={{ display: "none" }} accept="image/png,image/jpg,image/jpeg" onChange={this.fileValidate} />
                                    </label>
                                    <div>{this.state.file_data && <img src={this.state.file_data} height='100' width='100' />}</div>
                                    </Grid>
                                    <Grid md={6}>
                                        <p class="text-center"> <button type="button" class="btn send-btn" data-dismiss="modal" aria-label="Close" onClick={this.submitPostMessage}>Send</button></p>
                                    </Grid>
                                    
                                    </Grid>
                                </div>

                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>}
                </LoadingOverlay>
            </div>
        )
    }
}
export default Idea_submit_new;