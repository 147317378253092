import { Component } from 'react';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

class CookiesService {

    get(key) {
        return cookies.get(key);
    }

    set(key, value, options) {
        cookies.set(key, value, options);
    }

    remove(key) {
        cookies.remove(key);
    }
}

export default new CookiesService();