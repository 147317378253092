import React, { Component } from "react";
import { GoogleLogin } from "react-google-login";
import http from "../../http-common";
import Snahttpckbar from "@material-ui/core/Snackbar";
import AddMessage from "../view/Messages/AddMessage";
import CookiesService from '../../services/CookiesService';
import { Route , withRouter} from 'react-router-dom';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
    }
    this.signup = this.signup.bind(this);
  }

  signup(res) {
    this.setState({ errorMessage: "" });
    
    const googleresponse = {
      Name: res?.profileObj?.name,
      email: res?.profileObj?.email,
      googleId: res?.profileObj?.googleId,
      providerId: "Google"
    };
    if(googleresponse.googleId !== null) {
      console.log('google response', googleresponse);
    } else {
      console.log(' null google response', googleresponse);
    }
    
    http.post("/api/checkGoogleLogin", googleresponse)
      .then((response) => {
          if (response?.data?.code == "1") {
            var userData = response?.data?.data;
            // sessionStorage.setItem("userData", JSON.stringify(userData));
            CookiesService.set("userData", JSON.stringify(userData), {});
            this.props.history.push({pathname: '/youtube-step', state: {} });
            // window.location = "/dashboard";
          } else if (response?.data?.code == "0") {
            this.setState({ errorMessage: response?.data?.message });
            const param = {
              first_name: res.profileObj.givenName,
              last_name: res.profileObj.familyName,
              email: res.profileObj.email,
              googleId: res.profileObj.googleId,
              picture_url: res.profileObj.imageUrl,
            }
            this.props.history.push({ pathname: '/login', state: { param } });
            // window.location = "/login";
          }
      });
  }

  render() {
    const responseGoogle = response => {
      console.log(response);
      var res = response.profileObj;
      console.log(res);
      this.signup(response);
    };
    const { errorMessage } = this.state;
    console.log("errorMessage", errorMessage);
    return (
      <div>
        <h1 className='title-name'>Idea</h1>
        <GoogleLogin
          className='loginbtn'
          clientId='769224313452-43qur0mj34a2j5f9qdldhhsu7f7ht4st.apps.googleusercontent.com'
          // buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
        />
        {errorMessage !== "" && (
          <div className='errormessage-main'>
            <AddMessage isError={true} errorMessage={errorMessage} />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SignIn);
