import { Box, Grid, IconButton, Button, Avatar } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ShareIcon from "@material-ui/icons/Share";
import Rating from "../../../view/formComponent/rating";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import YouTubeIcon from '@material-ui/icons/YouTube';
import http from "../../../../http-common";
import rootConfig from "../../../../config";
import axios from "axios";
import StarRatings from 'react-star-ratings';
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router";
import NoDataFound from "../../../NoDataFound";

var FormData = require('form-data');



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}));

function CustomRating(props) {

    return (

        <Rating
            rating={props.rating}
            reviews_count={props.reviews_count}
        />
    );
}

export default function CompanyDetail(props) {
    const classes = useStyles();
    const history = useHistory();
    const [requestParms] = useState(props.location.state);
    const [companyDetailArray, setCompnayDetailArray] = useState();
    const [isActive, setIsActive] = useState(true);
    const [noDataFound, setNoDataFound] = useState(false);
    const [dataFound, setDataFound] = useState(true);
    console.log("requestParms.post_id", requestParms.post_id);

    function handleShare() {
        const company = [];
        company.push(companyDetailArray);
        console.log('company', company);
        var parmas = {
            company: company,
            isCompanyShare: true,
        }
        history.push({pathname: '/submit-idea', state: { parmas }});
    }

    useEffect(() => {

        var data = new FormData();
        data.append('post_id', requestParms.post_id);
        data.append('page', '1');
        data.append('per_page', '10');

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL || rootConfig.BaseURL + 'api/getInnovatorDetails',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(response.data.data);
                if(response.data.data.length !== 0) {
                    setCompnayDetailArray(response?.data?.data);
                    setDataFound(true);
                    // console.log(companyDetailArray.youtube_url[0]);
                    // console.log("Type of", typeof companyDetailArray?.avg_rating);
                    // companyDetailArray.avg_rating = parseFloat(companyDetailArray?.avg_rating).toFi;
                    // console.log("Type of", typeof companyDetailArray?.avg_rating);
                    setIsActive(false);
                } else {
                    setDataFound(false);
                    setNoDataFound(true);
                    setIsActive(false);
                }
            })
            .catch(function (error) {
                console.log("this error", error);
            });

    }, []);
    return (
        <>
            <div className='content-wrapper'>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Please wait...'
                >
                    <Grid container>
                        {dataFound &&
                        <>
                        <Grid md={12} className='d-flex'>
                            <Grid md={10}>
                                <div className='icon-wrap'>
                                    <IconButton onClick={handleShare}>
                                        <ShareIcon style={{ color: "#ff720a", fontSize: 25 }} />
                                    </IconButton>
                                </div>
                                <Box display="flex" justifyContent="center" m={2}>
                                    <img src={companyDetailArray?.attachment} alt="image" width="300" height="150" />
                                </Box>

                            </Grid>
                        </Grid>
                        <Grid className='d-flex' md={12}>
                            <Grid md={1}></Grid>
                            <Grid md={9}>
                                <Box display="flex" justifyContent="center" m={1}>
                                    <h1>{companyDetailArray?.post_title}</h1>
                                </Box>
                            </Grid>
                        </Grid>
                        
                        <Grid md={10}>
                            <Box display="flex" justifyContent="center" m={1}>
                                {isActive == false ? <CustomRating rating={parseFloat(companyDetailArray.avg_rating)} reviews_count={companyDetailArray?.reviews_count} /> : null}
                            </Box>
                            <Box p={1} display="flex" justifyContent="flex-end">
                                <Button variant="contained" size="large" color="primary" onClick={handleShare}>Share</Button>
                            </Box>
                            <Box pl={15} pr={15} display="flex" justifyContent="center">
                                <p>{companyDetailArray?.post_content}</p>
                            </Box>
                        </Grid>
                        <Grid md={10}>
                            <Box display="flex" justifyContent="center">
                                <Grid md={10}>
                                    <Box borderBottom={1} borderColor="grey.500" p={1}>
                                        <Grid md={12} className='d-flex'>
                                            <Grid md={5}>
                                                <h3>Business Website Address:</h3>
                                            </Grid>
                                            <Grid md={5}>
                                                <a href={companyDetailArray?.business_website_address} target="_blank">{companyDetailArray?.business_website_address}</a>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box borderBottom={1} borderColor="grey.500" p={1}>
                                        <Grid md={12} className='d-flex'>
                                            <Grid md={5}>
                                                <h3>Service Category:</h3>
                                            </Grid>
                                            <Grid md={7}>
                                                <p>{companyDetailArray?.service_category}</p>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box borderBottom={1} borderColor="grey.500" p={1}>
                                        <Grid md={12} className='d-flex'>
                                            <Grid md={5}>
                                                <h3>Specialization:</h3>
                                            </Grid>
                                            <Grid md={7}>
                                                <p>{companyDetailArray?.specialization}</p>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box borderBottom={1} borderColor="grey.500" p={1}>
                                        <Grid md={12} className='d-flex'>
                                            <Grid md={5}>
                                                <h3>Whatsapp Link:</h3>
                                            </Grid>
                                            <Grid md={7}>
                                                <a href={`https://wa.me/${companyDetailArray?.whatsapp_us_options.number}?text=${companyDetailArray?.whatsapp_us_options.pre_filled}`} target="_blank"><WhatsAppIcon style={{ color: "#4fce5d", fontSize: 50 }} /></a>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box borderBottom={1} borderColor="grey.500" p={1}>
                                        <Grid md={12} className='d-flex'>
                                            <Grid md={5}>
                                                <h3>Facebook:</h3>
                                            </Grid>
                                            <Grid md={7}>
                                                <a href={companyDetailArray?.facebook_url} target="_blank"><FacebookIcon style={{ color: "#3b5998", fontSize: 50 }} /></a>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box borderBottom={1} borderColor="grey.500" p={1}>
                                        <Grid md={12} className='d-flex'>
                                            <Grid md={5}>
                                                <h3>Twitter:</h3>
                                            </Grid>
                                            <Grid md={7}>
                                                <a href={companyDetailArray?.twitter_url} target="_blank"><TwitterIcon style={{ color: "#00acee", fontSize: 50 }} /></a>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box borderBottom={1} borderColor="grey.500" p={1}>
                                        <Grid md={12} className='d-flex'>
                                            <Grid md={5}>
                                                <h3>Youtube:</h3>
                                            </Grid>
                                            <Grid md={7}>
                                                <a href={companyDetailArray?.youtube_url[0]} target="_blank"><YouTubeIcon style={{ color: "#c4302b", fontSize: 50 }} /></a>
                                                <p>{companyDetailArray?.youtube_url.length}+ Videos</p>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box borderBottom={1} borderColor="grey.500" p={1}>
                                        <Grid md={12} className='d-flex'>
                                            <Grid md={5}>
                                                <h3>Region:</h3>
                                            </Grid>
                                            <Grid md={7}>
                                                <p>{companyDetailArray?.region}</p>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        </Grid>

                        {/* Reviews */}
                        <Grid md={10} className='d-flex'>
                            <Grid md={1}></Grid>
                            <Grid md={10}>
                                <Box m={1} p={1} boxShadow={1}>
                                    <h2 style={{ color: "#ff720a" }}>Reviews:</h2>
                                    {companyDetailArray?.company_reviews_list > 0 ?
                                        companyDetailArray?.company_reviews_list.map((item, index) => (
                                            <Box m={1} p={1} boxShadow={1} key={item.id}>
                                                <Grid md={12}>
                                                    <Box>
                                                        <Grid md={12} className='d-flex'>
                                                            <Grid md={2}>
                                                                <Avatar
                                                                    alt='Remy Sharp'
                                                                    src={item?.userImage}
                                                                    className={classes.large}
                                                                />
                                                            </Grid>
                                                            <Grid md={10}>
                                                                <h3>{item?.userName}</h3>
                                                                {item?.review ? <StarRatings rating={parseFloat(item?.review)} starRatedColor="#f28a16" starDimension="20px" numberOfStars={5} name='' /> : null}
                                                                <p>{item.review}</p>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        )) : null}
                                </Box>
                            </Grid>
                        </Grid>
                        </>}
                        {noDataFound && <><Grid md={12}><NoDataFound message='No record found' icon='fa fa-search' /> </Grid></> }
                    </Grid>
                </LoadingOverlay>
            </div >
        </>
    );
}
