import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Avatar, Grid, Button, IconButton } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import { spacing } from '@material-ui/system';
import LoadingOverlay from "react-loading-overlay";
import http from "../../../../http-common";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    colorOrange: {
        color: "#ff720a",
    },
    paddingLeft: {
        paddingLeft: 40
    },
    padding: {
        padding: 10,
    }
}));

export default function ProductDetail(props) {

    const classes = useStyles();
    const history = useHistory();
    const [requestParms] = useState(props.location.state);
    const [isActive, setIsActive] = useState(true);
    const [productDetailArray, setProductDetailArray] = useState();

    function handleShare() {
        const product = [];
        product.push(productDetailArray);
        console.log('product', product);
        var parmas = {
            product: product,
            isProductShare: true,
        }
        history.push({pathname: '/submit-idea', state: { parmas }});
    }

    useEffect(() => {
        var requestParams = {
            product_id: requestParms.product_id
        };
        http.post("product/getProductDetail", requestParams).then(response => {
            console.log(response?.data);
            setProductDetailArray(response?.data?.data);
            setIsActive(false);
        });
    }, []);
    return (
        <>
            <div className='content-wrapper'>
                <LoadingOverlay
                    active={isActive}
                    spinner
                    text='Please wait...'
                >
                    <Grid container>
                        <Grid md={10} spacing={3}>
                            <Grid className='d-flex'>
                                <Grid md={1}></Grid>
                                <Grid md={8} p={5}>
                                    <h1>{productDetailArray?.post_title}</h1>
                                </Grid>
                                <Grid md={1} style={{ margin: 'auto' }}>
                                    <div className='icon-wrap'>
                                        <IconButton onClick={handleShare}>
                                            <ShareIcon style={{ color: "#ff720a", fontSize: 25 }} />
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid md={10} className='d-flex' style={{ margin: 'auto' }}>
                            <Grid md={1}></Grid>
                            <Grid md={8} >
                                <img src={productDetailArray?.attachment} alt="image" width="100%" />
                            </Grid>
                        </Grid>
                        <Grid md={10} className='d-flex'>
                            <Grid md={1}></Grid>
                            <Grid md={5}>
                                <Box p={1} display="flex" justifyContent="flex-start">
                                    <h1 className={classes.colorOrange}>${productDetailArray?.price}</h1>
                                </Box>
                            </Grid>
                            <Grid md={5}>
                                <Box p={1} display="flex" justifyContent="flex-end">
                                    <Button variant="contained" size="large" color="primary" className={classes.margin} onClick={handleShare}>Share</Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid className='d-flex' md={12}>
                            <Grid md={1}></Grid>
                            <Grid md={8}>
                                <Box boxShadow={1} m={1} p={1}>
                                    <Grid md={8}>
                                        <h2 className={classes.colorOrange}>Category</h2>
                                        <p className={classes.padding}>{productDetailArray?.categories}</p>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid className='d-flex' md={12}>
                            <Grid md={1}></Grid>
                            <Grid md={8}>
                                <Box boxShadow={1} m={1} p={1}>
                                    <Grid md={12}>
                                        <h2 className={classes.colorOrange}>Description</h2>
                                        <p className={classes.padding}>{productDetailArray?.post_content}</p>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </LoadingOverlay>
            </div>
        </>
    );
}