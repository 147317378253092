import { Grid, Box } from "@material-ui/core";
import React, { Component } from "react";
import Icon from '@material-ui/core/Icon';


class NoDataFound extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Grid className='d-flex'>
                <Box style={{ margin: 'auto' }}>
                    {this.props.icon && <Icon className={this.props.icon} style={{ fontSize: '50px', left: "37%", position: "relative" }} />}
                    {this.props.image && <img src={this.props.image} style={{  position: "relative", marginTop: '10px' }}/>}
                    <h1 style={{textAlign: 'center'}}>{this.props.message}</h1>
                </Box>
            </Grid>
        )
    }
}

export default NoDataFound;