import React, { Component } from "react";
import { Avatar, Box, Grid, Typography, Button } from "@material-ui/core";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import http from "../../../http-common";
import LoadingOverlay from "react-loading-overlay";
import CookiesService from '../../../services/CookiesService';
import { Alert } from '@material-ui/lab';
import CompanyCard from "./CompanyCard";
import ProductCard from "./ProductCard";

class SendIdea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestParams: props.location.state.params,
            invalidImage: '',
            file_name: '',
            file_data: '',
            description: '',
            Users: [],
            user_id: 0,
            company_id: 0,
            product_id: 0,
            idea_title: '',
            status: 'published',
            problem_id: 0,
            company_name: '',
            product_name: '',
            product_price : '',
            product_image : '',
            product_description : '',
            company_description: '',
            company_avg_rating: 0,
            company_business_website: '',
            is_company_shared : 0,
            is_product_shared : 0,
            company_post_id: 0,
            isActive: false,
            success: '',
            error: ''
        }
        console.log('shareItem', this.state.requestParams.shareItem);
        if(this.state.requestParams.shareItem?.isCompanyShare == true) {
            this.state.is_company_shared = 1;
            this.state.company_name = this.state.requestParams.shareItem.company[0].post_title;
            this.state.company_description = this.state.requestParams.shareItem.company[0].post_content;
            this.state.company_post_id = this.state.requestParams.shareItem.company[0].company_post_id;
            this.state.company_avg_rating = this.state.requestParams.shareItem.company[0].avg_rating;
            this.state.company_business_website = this.state.requestParams.shareItem.company[0].company_business_website;
        }
        if(this.state.requestParams.shareItem?.isProductShare == true) {
            this.state.is_product_shared = 1;
            this.state.product_id = this.state.requestParams.shareItem.product[0].ID;
            this.state.product_name = this.state.requestParams.shareItem.product[0].post_title;
            this.state.product_price = this.state.requestParams.shareItem.product[0].price;
            this.state.product_image = this.state.requestParams.shareItem.product[0].attachment;
            this.state.product_description = this.state.requestParams.shareItem.product[0].post_content;
        }
        this.fileValidate = this.fileValidate.bind(this);
        this.reader = new FileReader();
        this.handleDescription = this.handleDescription.bind(this);
        this.CallApi = this.CallApi.bind(this);
        console.log("requestParams", this.state.requestParams);
    }

    fileValidate(event) {
        const imageFile = event.target.files[0];

        if (!imageFile) {
            this.setState({ invalidImage: 'Please select image.' });
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            this.setState({ invalidImage: 'Please select valid image.' });
            return false;
        }

        this.reader.readAsDataURL(imageFile);
        this.reader.onload = (e) => {
            this.state.file_name = imageFile.name;
            this.state.file_data = this.reader.result;
            this.setState({ invalidImage: null });
        };
    }

    handleDescription(event) {
        this.state.description = event.target.value;
    }

    CallApi() {
        this.setState({ isActive: true });
        this.state.users = this.state.requestParams.users.map((item, index) => (item.user_id)).toString();
        console.log("users", this.state.users);
        var userData = CookiesService.get("userData");
        // var userData = JSON.parse(sessionStorage.getItem("userData"));
        this.state.user_id = userData.user_id;
      
        console.log("params of forward", params);
        if(this.state.requestParams.shareItem?.is_forward == 1) {
            
            var params = {
                from_user_id: this.state.user_id,
                designation: userData.designation,
                idea_id : this.state.requestParams.shareItem.idea_id,
                users: this.state.users.toString(),
                company_id: this.state.requestParams.company_id,
                file_data: this.state.file_data,
                file_name: this.state.file_name,
            }
            http.post('api/forwardIdeaInsideNetwork', params)
            .then(response => {
                console.log(response.data);
                this.setState({ isActive: false });
                this.setState({ success: 'Idea Forward Successfully' }, () => {
                    window.setTimeout(() => {
                        this.setState({ success: '' });
                        window.location = "/idea-received";
                    }, 3000)
                });
            });
        } else {
            if(this.state.description.length > 20) {
                var params = {
                    users: this.state.users.toString(),
                    user_id: this.state.user_id,
                    company_id: this.state.requestParams.company_id,
                    idea_title: this.state.idea_title,
                    description: this.state.description,
                    status: this.state.status,
                    problem_id: this.state.problem_id,
                    company_name: this.state.company_name,
                    company_description: this.state.company_description,
                    company_avg_rating: this.state.company_avg_rating,
                    company_business_website: this.state.company_business_website,
                    is_company_shared: this.state.is_company_shared,
                    company_post_id: this.state.company_post_id,
                    is_product_shared: this.state.is_product_shared,
                    product_id : this.state.product_id,
                    product_name: this.state.product_name,
                    product_price: this.state.product_price,
                    product_image: this.state.product_image,
                    product_description: this.state.product_description,
                    file_data: this.state.file_data,
                    file_name: this.state.file_name,
                }
                http.post('/api/postideaTomultipleusers_ios', params)
                .then(response => {
                    console.log(response.data);
                    this.setState({ isActive: false });
                    this.setState({ success: 'Idea Send Successfully' }, () => {
                        window.setTimeout(() => {
                            this.setState({ success: '' });
                            window.location = "/idea-received";
                        }, 3000)
                    });
                });
            } else {
                this.setState({ isActive: false });
                this.setState({ error: 'please entering up to 20 characters' }, () => {
                    window.setTimeout(() => {
                        this.setState({ error: '' });
                    }, 3000)
                });
            }
        }
    }

    render() {
        var userData = CookiesService.get("userData");
        const classes = {
            widthFull: {
                position: "reletive",
                fontSize: 15,
                width: '100%',
                top: 10,
                right: 5
            },
            large: {
                width: "90px",
                height: "90px",
            },
            sizeIcon: {
                fontSize: 35,
                color: "#fe7500"
            },
            textCenter: {
                textAlign: "center",
            },
            buttonStyle: {
                backgroundColor: "#fe7500",
                color: "#fff",
                fontSize: 15,
                marginLeft: 10,
                textTransform: "none",
                float: "right"
            },
            textOrenge: {
                color: "#fe7500",
                marginTop: "8px",
                position: 'absolute'
            },
            arrow: {
                cursor: "pointer"
            },
            errorMessage: {
                color: 'red',
            }
        }
        return (
            <div className='content-wrapper'>
                <LoadingOverlay
                    active={this.state.isActive}
                    spinner
                    text='Please wait...'
                >
                    <Grid container>
                        {this.state.success && <div class="alert alert-success" role="alert" style={classes.widthFull}>{this.state.success}</div>}
                        {this.state.error && <div class="alert alert-danger" role="alert" style={classes.widthFull}>{this.state.error}</div>}
                        <Grid md={12}>
                            <Grid md={8}>
                                <Box m={2} justifyContent="center" alignItems="center" borderColor="grey.500" borderBottom={1} >
                                    <Avatar style={{ margin: "auto" }} src={this.state.requestParams.picture_url}></Avatar>
                                    <Typography variant="h2" align="center" gutterBottom>{this.state.requestParams.company_name}</Typography>
                                    <Typography variant="h5" align="center" gutterBottom>{this.state.requestParams.designationArray}</Typography>
                                </Box>
                                <div>
                                    {this.state.requestParams.shareItem?.isCompanyShare == true && <CompanyCard company={this.state.requestParams.shareItem.company[0]}/>}
                                    {this.state.requestParams.shareItem?.isProductShare == true && <ProductCard product={this.state.requestParams.shareItem.product[0]} />}
                                </div>
                                <Box justifyContent="center" m={2} alignItems="center" style={classes.textCenter} borderBottom={1}>
                                    <textarea
                                        rows={20}
                                        style={{width: '100%'}}
                                        placeholder={userData.username+'\n\n Submit an Idea or an Interesting thougth, or a Problem to a Solution Feel free to add an image or a document. Visuals have greater impact ! \n\n Your idea should ideally be no more then 100-200 word. Avoid generic and spammy ideas, as they will get rejected ! \n\n Let\'s Ideate !!! \n Ideate Team.'}
                                        name='description'
                                        onChange={this.handleDescription}
                                    ></textarea>
                                </Box>
                                <Box>
                                    <Grid md={12} className='d-flex'>
                                        <Grid md={5}>
                                            <label for="attachment" style={classes.arrow}>
                                                <AttachFileIcon style={classes.sizeIcon}></AttachFileIcon><span style={classes.textOrenge}>attachment</span>
                                                {this.state.invalidImage && <p style={classes.errorMessage}>{this.state.invalidImage}</p>}
                                                <input type="file" name="attachment" id="attachment" style={{ display: "none" }} accept="image/png,image/jpg,image/jpeg" onChange={this.fileValidate} />
                                            </label>
                                            <div>{this.state.file_data && <img src={this.state.file_data} height='100' width='100' />}</div>
                                        </Grid>
                                        <Grid md={7}>
                                            <Button variant="contained" size="large" style={classes.buttonStyle} onClick={this.CallApi}>Send</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </LoadingOverlay>
            </div>
        )
    }
}

export default SendIdea;