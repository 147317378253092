import React, { Component } from "react";
import { Avatar, Box, Grid, Typography, Button } from "@material-ui/core";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import http from "../../../../http-common";
import LoadingOverlay from "react-loading-overlay";
import CookiesService from '../../../../services/CookiesService';
import { Alert } from '@material-ui/lab';

import ApplicationLink from "./ApplicationLink";

class SendCoolInnovaion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestParams: props.location.state.params,
            invalidImage: '',
            file_name: '',
            file_data: '',
            description: '',
            Users: [],
            user_id: 0,
            isActive: false,
            success: '',
            error: ''
        }
        this.fileValidate = this.fileValidate.bind(this);
        this.reader = new FileReader();
        this.handleDescription = this.handleDescription.bind(this);
        this.CallApi = this.CallApi.bind(this);
        console.log("requestParams", this.state.requestParams);
    }

    fileValidate(event) {
        const imageFile = event.target.files[0];

        if (!imageFile) {
            this.setState({ invalidImage: 'Please select image.' });
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            this.setState({ invalidImage: 'Please select valid image.' });
            return false;
        }

        this.reader.readAsDataURL(imageFile);
        this.reader.onload = (e) => {   
            this.state.file_name = imageFile.name;
            this.state.file_data = this.reader.result;
            this.setState({ invalidImage: null });
        };

    }

    handleDescription(event) {
        this.state.description = event.target.value;
    }

    CallApi() {
        this.setState({ isActive: true });
        this.state.users = this.state.requestParams.users.map((item, index) => (item.user_id)).toString();
        console.log("users", this.state.users);
        var userData = CookiesService.get("userData");
        // var userData = JSON.parse(sessionStorage.getItem("userData"));
        this.state.user_id = userData.user_id;
        var params = {
            users: this.state.users.toString(),
            user_id: this.state.user_id,
            description: this.state.description,
            file_data: this.state.file_data,
            file_name: this.state.file_name,
        }
        console.log("params", params);
        http.post('GroupIdea/submitCoolInnovationIdea', params)
            .then(response => {
                console.log(response);
                this.setState({ isActive: false });
                if(response.data.code == 1) {
                    this.setState({ success: response.data.message}, () => {
                        window.setTimeout(() => {
                            this.setState({ success: '' });
                            window.location = "/dashboard";
                        }, 3000)
                    });
                } else {
                    this.setState({ error: response.data.message}, () => {
                        window.setTimeout(() => {
                            this.setState({ error: '' });
                        }, 3000)
                    });
                }
            });
    }

    render() {
        var userData = CookiesService.get("userData");
        const classes = {
            widthFull: {
                position: "reletive",
                fontSize: 15,
                width: '100%',
                top: 10,
                right: 5
            },
            large: {
                width: "90px",
                height: "90px",
            },
            sizeIcon: {
                fontSize: 35,
                color: "#fe7500"
            },
            textCenter: {
                textAlign: "center",
            },
            buttonStyle: {
                backgroundColor: "#fe7500",
                color: "#fff",
                fontSize: 15,
                marginLeft: 10,
                textTransform: "none",
                float: "right"
            },
            textOrenge: {
                color: "#fe7500",
                marginTop: "8px",
                position: 'absolute'
            },
            arrow: {
                cursor: "pointer"
            },
            errorMessage: {
                color: 'red',
            },
            
        }
        return (
            <div className='content-wrapper'>
                <LoadingOverlay
                    active={this.state.isActive}
                    spinner
                    text='Please wait...'
                >
                    <Grid container>
                        {this.state.success && <div class="alert alert-success" role="alert" style={classes.widthFull}>{this.state.success}</div>}
                        {this.state.error && <div class="alert alert-danger" role="alert" style={classes.widthFull}>{this.state.error}</div>}
                        <Grid md={12}>
                            <Grid md={8}>
                                {/* <Box m={2} justifyContent="center" alignItems="center" borderColor="grey.500" borderBottom={1} >
                                    <Avatar style={{ margin: "auto" }}></Avatar>
                                    <Typography variant="h2" align="center" gutterBottom>{this.state.requestParams.company_name}</Typography>
                                    <Typography variant="h5" align="center" gutterBottom>{this.state.requestParams.designationArray}</Typography>
                                </Box> */}
                                <Box justifyContent="center" m={2} alignItems="center" style={classes.textCenter} >
                                    <textarea
                                        rows={20}
                                        style={{width: '100%'}}
                                        placeholder={"Cool innovation of the day is the unique product/video idea that you can share with your network. You can add any youtube URL and put an interesting caption to it and 'Cool Innovation of the Day's is ready to go. \n\n Please wait for URL card to appear at the bottom when you add Youtube Video URL. Share it with your friends today!"}
                                        name='description'
                                        onChange={this.handleDescription}
                                    ></textarea>
                                </Box>
                                <div style={{margin:'auto'}}>
                                    <ApplicationLink/>
                                </div>
                                <Box>
                                    <Grid md={12} className='d-flex'>
                                        <Grid md={5}>
                                            <label for="attachment" style={classes.arrow}>
                                                <AttachFileIcon style={classes.sizeIcon}></AttachFileIcon><span style={classes.textOrenge}>attachment</span>
                                                {this.state.invalidImage && <p style={classes.errorMessage}>{this.state.invalidImage}</p>}
                                                <input type="file" name="attachment" id="attachment" style={{ display: "none" }} accept="image/png,image/jpg,image/jpeg" onChange={this.fileValidate} />
                                            </label>
                                            <div>{this.state.file_data && <img src={this.state.file_data} height='100' width='100' />}</div>
                                        </Grid>
                                        <Grid md={7}>
                                            <Button variant="contained" size="large" style={classes.buttonStyle} onClick={this.CallApi}>Send</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </LoadingOverlay>
            </div>
        )
    }
}

export default SendCoolInnovaion;