import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Enterprises from "./enterprises";
import Solutionists from "./solutionists";
import http from "../../../../http-common";
import { Checkbox, Grid } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

class SubmitIdea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            isOutsideUser: false,
            solutionistsList: [],
            enterprisesList: [],
            page: 1,
            per_page: 10,
            shareItem: this.props.location.state?.parmas
        }
        console.log('shareItem', this.state.shareItem);
        this.handleChange = this.handleChange.bind(this);
        this.callAPIOnPagination = this.callAPIOnPagination.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        if (e.target.checked) {
            this.setState({ isOutsideUser: true });
            this.props.history.push({pathname: '/add-user', state: {shareItem: this.state.shareItem}});
        } else {
            this.setState({ isOutsideUser: false });
        }
    }

    handleChange(event, newValue) {
        this.setState({ value: newValue });
    }

    callAPIOnPagination() {
        http.get(`/api/companiesByCompanyType?company_type="Solutionists"&page=${this.state.page}&per_page=${this.state.per_page}`).then(response => {
            this.setState({ solutionistsList: response.data.data });
            console.log("this.state.solutionistsList", this.state.solutionistsList);
        });
        http.get(`/api/companiesByCompanyType?company_type="Enterprises"&page=${this.state.page}&per_page=${this.state.per_page}`).then(response => {
            this.setState({ enterprisesList: response.data.data });
            console.log(this.state.enterprisesList);
        });
    }

    componentDidMount() {
        this.callAPIOnPagination();
    }

    render() {
        const classes = {
            large: {
                width: "45px",
                height: "45px",
                marginBottom: "10px"
            },
            sizeIcon: {
                fontSize: 30,
                color: "#fe7500"
            },
            textSize: {
                fontSize: "25px"
            },
            appbar: {
                background: "#25345a"
            }
        }
        return (
            <div class="content-wrapper" >
                <div className='recommend-main'>
                    <>
                        <AppBar position='static' >
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                aria-label='simple tabs example'
                                className='custom-tab-main'>
                                <Tab label='Enterprises' {...a11yProps(0)} />
                                <Tab label='Solutionists' {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <Grid md={12}>
                            <Box color="text.secondary" pt={2} pl={3} >
                                <Checkbox
                                    style={classes.large}
                                    icon={<CheckBoxOutlineBlankIcon style={classes.sizeIcon} />}
                                    checkedIcon={<CheckBoxIcon style={classes.sizeIcon} />}
                                    mb={5}
                                    onClick={this.handleClick}
                                ></Checkbox>
                                <span style={classes.textSize}>Submit idea outside the network</span>
                            </Box>
                        </Grid>
                        <TabPanel value={this.state.value} index={0}>
                            <Enterprises EnterprisesList={this.state.enterprisesList} shareItem={this.state.shareItem} />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={1}>
                            <Solutionists Solutionists={this.state.solutionistsList} shareItem={this.state.shareItem} />
                        </TabPanel>
                    </>
                </div>
            </div>
        )
    }
}
export default SubmitIdea;