import React, { Component } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import { IconButton, Box, Grid, Avatar } from '@material-ui/core';

// export default function
class AvatarUserList extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <div style={{ overflowX: 'auto' }}>
                    <Box ml={3}>
                        <Grid md={12} className='d-flex'>
                            {this.props.selectedUserList.map((item, index) => (
                                <Grid>
                                    <Box m={1}>
                                        <Avatar
                                            src={item?.picture_url}
                                        ></Avatar>
                                        <div style={{ position: 'relative', marginTop: "-20px", marginLeft: "15px" }}>
                                            <IconButton
                                                value={item?.user_id}
                                                onClick={() => this.props.handleUserList(item.user_id, item.company_id)}
                                            >
                                                <CancelIcon fontSize="small"></CancelIcon>
                                            </IconButton>
                                        </div>
                                        <span style={{ fontSize: 10 }}>{item?.designation}</span>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </div >
            </>

        )
    }
}

export default AvatarUserList;