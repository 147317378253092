import React, { Component } from "react";
import http from "../http-common";
import CookiesService from '../services/CookiesService';

class Logout extends Component {
    constructor(props) {

        super(props);
        this.state = {
            user_id: 0
        };
        var userData = CookiesService.get("userData");
        // var userData = JSON.parse(sessionStorage.getItem("userData"));
        this.state.user_id = userData.user_id;
        this.logout = this.logout.bind(this);
    }

    logout() {

        var params = {
            user_id: this.state.user_id
        }
        http.post('/api/logout', params)
            .then((response) => {
                console.log('hello');
                CookiesService.remove('userData');
                // sessionStorage.removeItem('userData');
                this.props.history.push('/');
            });
    }

    render() {
        return (
            <div class="content-wrapper">
                <section class="content-header">
                    <h1> logout </h1>
                    <button onClick={this.logout}>logout</button>
                </section>
            </div>

        )
    }
}
export default Logout;