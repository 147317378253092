import React, { Component } from "react";
import Pagination from 'react-bootstrap/Pagination';
import http from "../../../../http-common";
import { Box, Avatar, Grid, IconButton } from "@material-ui/core";
import Rating from "../../../view/formComponent/rating";
import ShareIcon from "@material-ui/icons/Share";
import { withRouter } from 'react-router-dom';
import SearchBar from '../SearchBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import NoDataFound from "../../../NoDataFound";

class CompanyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyList: [],
            search: "",
            isActive: true,
            totalCompany: "0",
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
            paginationIteams: [],
            actualTotalPage: 0,
            noDataFound: false
        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.callAPIOnPagination = this.callAPIOnPagination.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.handleShare = this.handleShare.bind(this);
    }

    handleShare(id) {
        const company = this.state.companyList.filter((item) => item.ID == id);
        console.log('company', company);
        var parmas = {
            company: company,
            isCompanyShare: true,
        }
        this.props.history.push({pathname: '/submit-idea', state: { parmas }});
    }

    handleChangeSearch(newValue) {
        this.state.search = newValue;
        this.state.paginationIteams = [];
        this.setState({ isActive: true });
        this.callAPIOnPagination();
    }

    handlePageClick(event) {
        console.log(event);
        //console.log(Number(event.target.id));
        this.state.currentPage = parseInt(event.target.id);
        //this.setState({currentPage:parseInt(event.target.id)});
        this.state.paginationIteams = [];
        this.setState({ isActive: true });
        this.callAPIOnPagination();
    }

    callAPIOnPagination() {
        var params = {
            page: this.state.currentPage,
            per_page: this.state.perPage,
            search: this.state.search
        }
        http.post("api/searchCompaniesDataList", params)
            .then((response) => {
                if (response.data.total_count) {
                    this.state.companyList = response.data.data;
                    // console.log("companyList", this.state.companyList[0].ID);
                    this.state.totalCompany = parseInt(response.data.total_count);
                    var total_pages = Math.ceil(this.state.totalCompany / this.state.perPage);
                    if (this.state.totalCompany > this.state.perPage) {
                        this.state.actualTotalPage = total_pages;

                        this.state.totalPages = (total_pages > 10) ? 10 : total_pages;

                        var newPaginationItems = [];
                        newPaginationItems.push(<Pagination.First onClick={this.firstPage} />,
                            <Pagination.Prev onClick={this.previousPage} />);
                        for (let number = 1; number <= this.state.totalPages; number++) {
                            newPaginationItems.push(
                                <Pagination.Item key={number} active={number === this.state.currentPage} id={number} onClick={this.handlePageClick}>
                                    {number}
                                </Pagination.Item>,
                            );
                        }
                        newPaginationItems.push(<Pagination.Next onClick={this.nextPage} />,
                            <Pagination.Last onClick={this.lastPage} />);
                        this.state.paginationIteams = newPaginationItems;
                    }
                    this.state.noDataFound = false;
                } else {
                    this.state.companyList = [];
                    this.state.paginationIteams = [];
                    this.state.noDataFound = true;
                }
                this.setState({ isActive: false });
                //console.log(this.state);
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.callAPIOnPagination();
    }

    firstPage() {
        if (this.state.currentPage = 1) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    lastPage() {
        if (this.state.currentPage = this.state.actualTotalPage) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    nextPage() {
        if (this.state.currentPage < this.state.actualTotalPage) {
            this.state.currentPage = parseInt(this.state.currentPage) + 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    previousPage() {
        if (this.state.currentPage > 1) {
            this.state.currentPage = parseInt(this.state.currentPage) - 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }


    render() {
        const classes = (theme) => ({
            root: {
                display: 'flex',
                '& > *': {
                    margin: theme.spacing(1),
                },
            },
            small: {
                width: theme.spacing(3),
                height: theme.spacing(3),
            },
            large: {
                width: theme.spacing(10),
                height: theme.spacing(10)
            },
        });
        return (
            <>
                <Grid container>
                    <Grid md={12}>
                        <SearchBar placeholder="Search Category by try typing 'Company'" onChangeValue={this.handleChangeSearch} />
                        {this.state.isActive ? <div style={{ position: "absolute", top: "150px", left: "50%" }}><CircularProgress /></div> :
                            this.state.companyList.map((item, index) => (
                                <Box boxShadow={2} bgcolor='background.paper' m={1} p={1}>
                                    <Grid md={12} className='d-flex'>
                                        <Grid md={1} style={{margin: 'auto'}}>
                                            <Avatar
                                                alt='Remy Sharp'
                                                src={item?.attachment}
                                                // className={classes.large}
                                                style={{ width: "90px", height: "90px" }}
                                                onClick={() => { this.props.history.push({ pathname: '/company-detail', state: { post_id: item.ID } }) }}
                                            />
                                        </Grid>
                                        <Grid md={10}>
                                            <Box pl={1}>
                                                <h4 onClick={() => { this.props.history.push({ pathname: '/company-detail', state: { post_id: item.ID } }) }}>{item?.post_title}</h4>
                                                <Rating
                                                    rating={item?.avg_rating}
                                                    reviews_count={item?.reviews_count}
                                                />

                                                <p className='fs-14'>{item?.post_content.substr(0, 250) + "..."}</p>
                                                <h4>
                                                    BizofitScore:{" "}
                                                    <span className='color-orange fs-14'>
                                                        {item?.bizofit_score}
                                                    </span>
                                                </h4>
                                            </Box>
                                        </Grid>
                                        <Grid md={1} style={{margin: 'auto'}}>
                                            <div className='icon-wrap'>
                                                <IconButton onClick={this.handleShare.bind('id',item.ID)}><ShareIcon style={{ color: "#ff720a", fontSize: 25 }}/></IconButton>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}

                        {this.state.paginationIteams &&
                            <Grid className="d-flex">
                                <Box justifyContent="center" style={{ margin: "auto" }}>
                                    <Pagination>{this.state.paginationIteams}</Pagination>
                                </Box>
                            </Grid>
                        }
                        {this.state.noDataFound == true && <NoDataFound message='No record found' icon='fa fa-search' />}
                    </Grid>
                </Grid>
            </>
        );
    };

}

export default withRouter(CompanyList);