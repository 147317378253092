import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Dashboard from "../Dashboard";
import FavouriteIdeas from "../FavouriteIdeas";
import Idea_submitted from "../Idea_submitted";
import Profile from "../Profile";
import Idea_received from "../Idea_received";
import Preferences from "../Preferences";
import Logout from "../Logout";
import RecommendEngine from "../Pages/SideBarMenu/RecommendEngine/RecommendEngine";
import ProblemStatementsList from "../Pages/SideBarMenu/ProblemStatements/ProblemStatementsList";
import ProblemStatementsDetail from "../Pages/SideBarMenu/ProblemStatements/ProblemStatementsDetail";
import ProductDetail from "../Pages/SideBarMenu/RecommendEngine/ProductDetail";
import CompanyDetail from "../Pages/SideBarMenu/RecommendEngine/CompanyDetail";
import SpecializationCompanyList from "../Pages/SideBarMenu/RecommendEngine/SpecializationCompanyList";
import SubmitIdea from "../Pages/SideBarMenu/SubmitIdea/SubmitIdea";
import MyTeam from "../Pages/SideBarMenu/MyTeam/MyTeam";
import EmployeeList from "../Pages/SideBarMenu/SubmitIdea/employeeList";
import SendIdea from "../Pages/SideBarMenu/SendIdea";
import AddOutsideUser from "../Pages/SideBarMenu/SubmitIdea/AddOutsideUser";
import CoolInnovation from "../Pages/SideBarMenu/SubmitCoolInnovation/CoolInnovation";
import EmployeeListCoolInnovation from "../Pages/SideBarMenu/SubmitCoolInnovation/employeeList";
import SendCoolInnovaion from "../Pages/SideBarMenu/SubmitCoolInnovation/SendCoolInnovation";
import AddOutsideUserCoolInnovation from "../Pages/SideBarMenu/SubmitCoolInnovation/addOutSideUser";
import CreateProblem from "../Pages/SideBarMenu/PostProblem/PostProblem";
import SelectCategory from "../Pages/SideBarMenu/PostProblem/SelectCategory";
import LoginDetail from "../Authentication/LoginDetail";
import CompanyTeam from "../Pages/SideBarMenu/CompanyTeam/CompanyTeam";
// import Home from "../Home";

class Mainlayout extends Component {
  render() {
    return (
      <div>
        <div id='wrapper'>
          <Header />
          <Router>
            <Sidebar />
            <Switch>
              {/* <Route path="/signup" component={SignUp}></Route> 
                  <Route path="/home" component={Home}></Route> */}
              <Route path='/home' component={Dashboard}></Route>
              <Route path='/dashboard' component={SubmitIdea}></Route>
              <Route path='/favourite-ideas' component={FavouriteIdeas}></Route>

              <Route path='/idea-submitted' component={Idea_submitted}></Route>
              <Route path='/profile' component={Profile}></Route>

              {/* sendIdea */}
              <Route path='/send-idea' component={SendIdea}></Route>
              {/* SubmitIdea */}
              <Route path='/submit-idea' component={SubmitIdea}></Route>
              <Route path='/employee-list' component={EmployeeList}></Route>
              <Route path='/add-user' component={AddOutsideUser}></Route>

              <Route path='/idea-received' component={Idea_received}></Route>
              {/* RecommendEngine */}
              <Route path='/recommend-engine' component={RecommendEngine}></Route>
              <Route path="/specialization-company-list" component={SpecializationCompanyList}></Route>
              <Route path='/product-detail' component={ProductDetail}></Route>
              <Route path='/company-detail' component={CompanyDetail}></Route>

              {/* Post Problem */}
              <Route path='/post-problem' component={CreateProblem}></Route>
              <Route path='/select-category' component={SelectCategory}></Route>

              {/* CoolInnovation */}
              <Route path='/cool-innovation' component={CoolInnovation}></Route>
              <Route path='/employee-list-cool-innovation' component={EmployeeListCoolInnovation}></Route>
              <Route path='/send-cool-innovation' component={SendCoolInnovaion}></Route>
              <Route path='/add-user-cool-innovation' component={AddOutsideUserCoolInnovation}></Route>

              <Route path='/preferences' component={Preferences}></Route>
              <Route path='/logout' component={Logout}></Route>
              {/* ProblemStatements */}
              <Route path='/problem-statement' component={ProblemStatementsList}></Route>
              <Route path='/problem-statement-detail' component={ProblemStatementsDetail}></Route>
              {/* MyTeam */}
              <Route path='/myteam' component={MyTeam}></Route>
              <Route path='/company-team' component={CompanyTeam}></Route>


            </Switch>
          </Router>
          {/* <Footer/> */}
        </div>
      </div>
    );
  }
}
export default Mainlayout;
