import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import http from "../../../../http-common";
import { Button, Checkbox, Grid } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AvatarUserList from "./AvatarUserList";
import SolutionistsCoolInovation from "./solutionists";
import EnterprisesCoolInnovation from "./enterprises";


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class CoolInnovation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      isOutsideUser: false,
      selectedUserList: props.location.state?.params.users ? props.location.state?.params.users : [],
      enterprisesList: [],
      page: 1,
      per_page: 10,
      isOutsideUser: false,
      isSubmitIdea: false

    }
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmitIdea = this.handleSubmitIdea.bind(this);
    this.handleUserList = this.handleUserList.bind(this);
    if (this.state.selectedUserList.length != 0) {
      this.state.isSubmitIdea = true;
    }
  }

  handleUserList(user_id, company_id) {
    this.state.selectedUserList = this.state.selectedUserList.filter((item) => {
      if (item.user_id != user_id) {
        return item;
      }
    });
    if (this.state.selectedUserList.length == 0) {
      this.state.isSubmitIdea = false;
    }
    this.setState({ selectedUserList: this.state.selectedUserList });

  }

  handleClick(e) {
    if (e.target.checked) {
      this.setState({ isOutsideUser: true });
      this.props.history.push({ pathname: '/add-user-cool-innovation' });
    } else {
      this.setState({ isOutsideUser: false });
    }
  }

  handleSubmitIdea() {
    var params = {
      users: this.state.selectedUserList
    }
    console.log('params', params);
    this.props.history.push({ pathname: '/send-cool-innovation', state: { params } });
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue });
  }

  render() {
    const classes = {
      large: {
        width: "45px",
        height: "45px",
        marginBottom: "10px"
      },
      sizeIcon: {
        fontSize: 30,
        color: "#fe7500"
      },
      textSize: {
        fontSize: "25px"
      },
      appbar: {
        background: "#25345a"
      },
      buttonStyle: {
        backgroundColor: "#fe7500",
        color: "#fff",
        fontSize: 20,
        marginLeft: 10,
        textTransform: "none"
      },
    }
    return (
      <div class="content-wrapper" >
        <div className='recommend-main'>
          <>
            <AppBar position='static' >
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                aria-label='simple tabs example'
                className='custom-tab-main'>
                <Tab label='Enterprises' {...a11yProps(0)} />
                <Tab label='Solutionists' {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <Grid md={12} className='d-flex'>
              <Grid md={12}>
                <Box color="text.secondary" pt={2} pl={3} >
                  <Checkbox
                    style={classes.large}
                    icon={<CheckBoxOutlineBlankIcon style={classes.sizeIcon} />}
                    checkedIcon={<CheckBoxIcon style={classes.sizeIcon} />}
                    mb={5}
                    onClick={this.handleClick}
                  ></Checkbox>
                  <span style={classes.textSize}>Submit idea outside the network</span>
                </Box>
              </Grid>
              <Grid md={4}>
                <Box m={2}>
                  {this.state.isSubmitIdea && <Button variant="contained" size="large" style={classes.buttonStyle} onClick={this.handleSubmitIdea}>Submit Idea</Button>}
                </Box>
              </Grid>
            </Grid>
            <AvatarUserList selectedUserList={this.state.selectedUserList} handleUserList={this.handleUserList}></AvatarUserList>
            <TabPanel value={this.state.value} index={0}>
              <EnterprisesCoolInnovation selectedUserList={this.state.selectedUserList} />
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
              <SolutionistsCoolInovation selectedUserList={this.state.selectedUserList} />
            </TabPanel>
          </>
        </div>
      </div >
    )
  }
}
export default CoolInnovation;