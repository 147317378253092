import { Box } from '@material-ui/core';
import React, {Component} from 'react';
import StarRatings from 'react-star-ratings';

class CompanyCard extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <Box borderRadius={10} boxShadow={2} m={2} p={1}>
                    <h3>{this.props.company.post_title.substr(0, 100) + "..."}</h3>
                    <StarRatings
                        rating={parseFloat(this.props.company.avg_rating)}
                        starRatedColor="#f28a16"
                        starDimension="20px"
                        numberOfStars={5}
                        name=''
                    />
                    <p>{this.props.company.post_content.substr(0, 250) + "..."}</p>
                </Box>
            </div>
        )
    }
}

export default CompanyCard;