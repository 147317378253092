import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: {
    display: "block",
    marginTop: theme.spacing(5)
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 500
  },
  input: {
    fontSize: 25
  },
  select: {
    fontSize: 20,
    width: "100%",
  },
  selectOption: {
    fontSize: 20
  }
}));

export default function ControlledOpenSelect(props) {
  const classes = useStyles();
  //   const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);

  //   const handleChange = event => {
  //     setAge(event.target.value);
  //   };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      {/* <Button className={classes.button} onClick={handleOpen}>
        Open the select
      </Button> */}
      <FormControl className={classes.formControl}>
        <InputLabel id='demo-controlled-open-select-label' className={classes.input}>
          {props?.label}
        </InputLabel>
        <Select
          labelId='demo-controlled-open-select-label'
          id='demo-controlled-open-select'
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={props?.value}
          onChange={props?.handleChange}
          className={classes.select}
        >
          <MenuItem value='' className={classes.selectOption}>
            <em>None</em>
          </MenuItem>
          {props?.dropDownArray.map((item, index) => (
            <MenuItem value={item?.id} className={classes.selectOption}>{item?.value}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
